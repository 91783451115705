import { navigate } from "@reach/router"
import * as React from "react"
import { AutoSizer, Column, Table, WindowScroller } from "react-virtualized"
import "react-virtualized/styles.css"
import { EditPencil, Trash, ViewShow } from "react-zondicons"
import { DefaultColors } from "util/colors"
import { Context } from "../../Context"
import Checkbox from "../checkbox"
import styles from "./styles"
import { getTransformedKey } from "util/helpers"
import get from "lodash.get"
import { RunFilterType, RunType } from "types/index"
import NoRows from "./components/no-rows"

type Props = {
    data?: any[]
    context: Context
    onRequestDelete: (id: string) => void
}

type State = {
    selectAll?: boolean
    sortBy?: string
    sortDirection?: string
}

export default class Runs extends React.Component<Props, State> {
    state = {
        selectAll: false,
        sortBy: null,
        sortDirection: null
    }
    toggleSelectAll = (event) => {
        this.setState({ selectAll: event.target.checked })
        if (event.target.checked) {
            const all = this.props.data.map((d) => d.id)
            this.props.context.selectRun(all)
        } else {
            this.props.context.deselectRun([])
        }
    }
    getStyleForRow = ({ index }) => {
        const style = {}
        if (index !== -1) {
            style["borderTop"] = "1px solid"
            style["borderColor"] = DefaultColors.gray[300]
        }
        if (index % 2 && index !== -1) {
            style["backgroundColor"] = DefaultColors.gray[100]
        }
        if (index === -1) {
            style["backgroundColor"] = DefaultColors.gray[200]
        }
        return style
    }
    handleRowSelect = (id: string) => {
        if (this.props.context.state.selectedRuns.indexOf(id) !== -1) {
            this.props.context.deselectRun([id])
        } else {
            this.props.context.selectRun([id])
        }
    }
    handleRowClick = ({ rowData }) => {
        const { id } = rowData
        // Navigate to edit run
        navigate(`/runs/${id}`)
    }
    isRowChecked = (id: string) => {
        if (this.props.context.state.selectedRuns.indexOf(id) !== -1) {
            return true
        } else {
            return false
        }
    }
    getActions = ({ rowData }) => {
        return (
            <div>
                <ViewShow
                    size={14}
                    onClick={() => navigate(`/runs/${rowData.id}`)}
                    style={{ marginRight: 16, fill: "#999999" }}
                    className="action-view"
                />
                <EditPencil
                    size={14}
                    onClick={() =>
                        navigate(`/runs/${rowData.id}`, {
                            state: { mode: "edit" }
                        })
                    }
                    style={{ marginRight: 16, fill: "#999999" }}
                    className="action-edit"
                />
                <Trash
                    size={14}
                    onClick={() => this.props.onRequestDelete(rowData.id)}
                    style={{ fill: "#999999" }}
                    className="action-delete"
                />
            </div>
        )
    }
    onColumnClick = ({ event, dataKey }) => {
        const noClickColumns = ["selected", "actions"]
        if (noClickColumns.indexOf(dataKey) !== -1) {
            event.stopPropagation()
        }
    }
    renderEmptyTable = () => {
        return (
            <div style={styles.noRows}>
                {this.props.data.length ? (
                    <span>No runs match the filter criteria.</span>
                ) : (
                    <span>No runs yet. Add one now?</span>
                )}
            </div>
        )
    }
    sort = ({ defaultSortDirection, event, sortBy, sortDirection }) => {
        this.setState({ sortBy, sortDirection })
    }
    sortData = () => {
        const data = this.filterRuns()
        const { sortBy, sortDirection } = this.state
        const { key, type } = getTransformedKey(sortBy)

        return data.sort((a, b) => {
            let formattedA = get(a, key)
            let formattedB = get(b, key)
            if (type === "number") {
                if (formattedA && formattedA !== "") {
                    formattedA = parseFloat(formattedA)
                }
                if (formattedB && formattedB !== "") {
                    formattedB = parseFloat(formattedB)
                }
            }
            // Test what happens when null vs 2.56
            if (sortDirection === "ASC") {
                if (formattedA === formattedB) {
                    return 0
                } else if (formattedA === null || formattedA === "") {
                    return 1
                } else if (formattedB === null || formattedB === "") {
                    return -1
                } else if (formattedA < formattedB) {
                    return -1
                } else if (formattedA > formattedB) {
                    return 1
                }
            } else {
                if (formattedA === formattedB) {
                    return 0
                } else if (formattedA === null || formattedA === "") {
                    return -1
                } else if (formattedB === null || formattedB === "") {
                    return -1
                } else if (formattedA > formattedB) {
                    return -1
                } else if (formattedA < formattedB) {
                    return 1
                }
            }
        })
    }
    filterRuns = () => {
        const { filters } = this.props.context.state
        let runs = this.props.context.state.runs.filter((run: RunType) => {
            return (
                run.vehicle === this.props.context.state.selectedVehicle.id &&
                (filters.track.length
                    ? filters.track.find((t) => t === run.timeslip.track)
                    : true) &&
                (filters.event.length
                    ? filters.event.find((t) => t === run.timeslip.event)
                    : true) &&
                (filters.length.length
                    ? filters.length.find((t) => t === run.timeslip.length)
                    : true) &&
                (filters.lane.length
                    ? filters.lane.find((t) => t === run.timeslip.lane)
                    : true) &&
                (filters.startDate
                    ? run.timeslip.date > filters.startDate
                    : true) &&
                (filters.endDate ? run.timeslip.date < filters.endDate : true)
            )
        })
        return runs
    }
    render() {
        if (this.props.data) {
            const sorted = this.sortData()
            return (
                <div style={{ flex: 1 }}>
                    <AutoSizer>
                        {({ width, height }) => {
                            return (
                                <Table
                                    width={width}
                                    height={height}
                                    headerHeight={60}
                                    rowHeight={60}
                                    rowCount={sorted.length}
                                    rowGetter={({ index }) => {
                                        return sorted[index]
                                    }}
                                    headerStyle={styles.header}
                                    className="table"
                                    rowStyle={this.getStyleForRow}
                                    onRowClick={this.handleRowClick}
                                    onColumnClick={this.onColumnClick}
                                    noRowsRenderer={this.renderEmptyTable}
                                    sort={this.sort}
                                    sortBy={this.state.sortBy}
                                    sortDirection={this.state.sortDirection}
                                >
                                    <Column
                                        headerRenderer={() => (
                                            <Checkbox
                                                checked={this.state.selectAll}
                                                onChange={this.toggleSelectAll}
                                                className=""
                                            />
                                        )}
                                        label=""
                                        dataKey="selected"
                                        width={width / 8}
                                        style={Object.assign({}, styles.cell, {
                                            padding: 6,
                                            marginLeft: 4
                                        })}
                                        cellRenderer={({ rowData }) => {
                                            return (
                                                <Checkbox
                                                    checked={this.isRowChecked(
                                                        rowData.id
                                                    )}
                                                    onChange={() =>
                                                        this.handleRowSelect(
                                                            rowData.id
                                                        )
                                                    }
                                                    className=""
                                                />
                                            )
                                        }}
                                        disableSort
                                    />
                                    <Column
                                        label="Event"
                                        dataKey="event"
                                        width={width / 2}
                                        style={styles.cell}
                                        cellDataGetter={({ rowData }) =>
                                            rowData.timeslip.event !== ""
                                                ? rowData.timeslip.event
                                                : "No Event Provided"
                                        }
                                    />
                                    <Column
                                        label="Track"
                                        dataKey="track"
                                        width={width / 2}
                                        style={styles.cell}
                                        cellDataGetter={({ rowData }) =>
                                            rowData.timeslip.track !== ""
                                                ? rowData.timeslip.track
                                                : "No Track Provided"
                                        }
                                    />
                                    <Column
                                        label="Session"
                                        dataKey="session"
                                        width={width / 4}
                                        style={styles.cell}
                                        cellDataGetter={({ rowData }) =>
                                            rowData.timeslip.session !== ""
                                                ? rowData.timeslip.session
                                                : "-"
                                        }
                                    />
                                    <Column
                                        width={width / 6}
                                        label="Lane"
                                        dataKey="lane"
                                        style={Object.assign(
                                            {},
                                            styles.cell
                                            // styles.cellLast
                                        )}
                                        cellDataGetter={({ rowData }) =>
                                            rowData.timeslip.lane !== ""
                                                ? rowData.timeslip.lane
                                                : "-"
                                        }
                                    />
                                    <Column
                                        width={width / 4}
                                        label={`60'`}
                                        dataKey="sixty"
                                        style={Object.assign(
                                            {},
                                            styles.cell
                                            // styles.cellLast
                                        )}
                                        cellDataGetter={({ rowData }) =>
                                            rowData.timeslip.sixty !== ""
                                                ? rowData.timeslip.sixty
                                                : "-"
                                        }
                                    />
                                    <Column
                                        width={width / 4}
                                        label={`330'`}
                                        dataKey="threeThirty"
                                        style={Object.assign(
                                            {},
                                            styles.cell
                                            // styles.cellLast
                                        )}
                                        cellDataGetter={({ rowData }) =>
                                            rowData.timeslip.threeThirty !== ""
                                                ? rowData.timeslip.threeThirty
                                                : "-"
                                        }
                                    />
                                    <Column
                                        width={width / 4}
                                        label={`1/8`}
                                        dataKey="sixSixty"
                                        style={Object.assign(
                                            {},
                                            styles.cell
                                            // styles.cellLast
                                        )}
                                        cellDataGetter={({ rowData }) =>
                                            rowData.timeslip.sixSixty !== ""
                                                ? rowData.timeslip.sixSixty
                                                : "-"
                                        }
                                    />
                                    <Column
                                        width={width / 4}
                                        label={`1/8 MPH`}
                                        dataKey="sixSixtyMph"
                                        style={Object.assign(
                                            {},
                                            styles.cell
                                            // styles.cellLast
                                        )}
                                        cellDataGetter={({ rowData }) =>
                                            rowData.timeslip.sixSixtyMph !== ""
                                                ? rowData.timeslip.sixSixtyMph
                                                : "-"
                                        }
                                    />
                                    <Column
                                        width={width / 4}
                                        label={`1000'`}
                                        dataKey="thousand"
                                        style={Object.assign(
                                            {},
                                            styles.cell
                                            // styles.cellLast
                                        )}
                                        cellDataGetter={({ rowData }) =>
                                            rowData.timeslip.thousand !== ""
                                                ? rowData.timeslip.thousand
                                                : "-"
                                        }
                                    />
                                    <Column
                                        width={width / 4}
                                        label={`1/4`}
                                        dataKey="thirteenTwenty"
                                        style={Object.assign(
                                            {},
                                            styles.cell
                                            // styles.cellLast
                                        )}
                                        cellDataGetter={({ rowData }) =>
                                            rowData.timeslip.thirteenTwenty !==
                                            ""
                                                ? rowData.timeslip
                                                      .thirteenTwenty
                                                : "-"
                                        }
                                    />
                                    <Column
                                        width={width / 4}
                                        label={`1/4 MPH`}
                                        dataKey="thirteenTwentyMph"
                                        style={Object.assign(
                                            {},
                                            styles.cell
                                            // styles.cellLast
                                        )}
                                        cellDataGetter={({ rowData }) =>
                                            rowData.timeslip
                                                .thirteenTwentyMph !== ""
                                                ? rowData.timeslip
                                                      .thirteenTwentyMph
                                                : "-"
                                        }
                                    />
                                    <Column
                                        width={width / 4}
                                        label={`Actions`}
                                        dataKey="actions"
                                        style={Object.assign({}, styles.cell)}
                                        cellRenderer={this.getActions}
                                        disableSort
                                    />
                                </Table>
                            )
                        }}
                    </AutoSizer>
                </div>
            )
        } else {
            return <div data-testid="empty" />
        }
    }
}
