export default {
    container: {
        height: "100%",
        width: "100%"
    },
    row: {
        display: "flex",
        alignItems: "center" as "center"
    },
    title: {
        width: 200
    },
    name: {
        fontSize: 11,
        color: "#212121",
        marginBottom: 0
    },
    track: {
        fontSize: 10,
        color: "#777777",
        marginTop: 0
    },
    header: {
        // backgroundColor: "#ededed",
        paddingTop: 4,
        paddingBottom: 4
        // paddingLeft: 12
        // display: "flex",
        // flexDirection: "row" as "row"
    },
    cell: {
        fontSize: 14
    },
    cellValue: {
        fontSize: 10
    },
    body: {
        paddingLeft: 12,
        height: 400
    },
    noRows: {
        position: "absolute" as "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center" as "center",
        justifyContent: "center" as "center",
        fontSize: 24,
        color: "#bdbdbd",
    }
}
