export default {
    container: {
        borderRadius: 3,
        display: "flex",
        flexDirection: "column" as "column"
    },
    row: {
        display: "flex",
        flexDirection: "row" as "row",
        flex: 1,
        alignItems: "center" as "center",
        justifyContent: "space-between"
    },
    left: {
        display: "flex",
        flexDirection: "row" as "row",
        flex: 1,
        alignItems: "center" as "center"
    },
    planeSelect: {
        marginRight: 16
    },
    dateRange: {
        display: "flex",
        marginLeft: 24
    }
}