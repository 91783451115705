/**
 * 1. Accept a user object (type this out via typescript)
 * 2. Check bypass first
 * 3. Load subscriptions from platform provider
 * 4. Setup a separate listener for when subscription changes
 */
import firebase from "firebase"
import { shouldBypassSubscriptionCheck } from "./helpers"
const functionsURL = "https://us-central1-dragtuner-de058.cloudfunctions.net"
// const functionsURL = "http://localhost:3000/dragtuner-de058/us-central1"

type SubscriptionResponse = {
    valid: boolean
    message: string
}

/**
 *
 * @param DBRef
 * @param subscriptionUpdatedCallback
 * Run all three of these subscription checks asynchronously and callback when success. Keep track of the amount of tries, if it returns 3 times, then call the callback with false
 */
export const CheckIfUserHasValidSubscription = (
    dbRef,
    user: firebase.User,
    subscriptionUpdatedCallback: (valid: boolean) => void
) => {
    const tries = {
        max: 2,
        current: 0,
        calledBack: false
    }
    setTimeout(() => {
        CheckIfUserHasValidSubscriptionStatus(user).then(
            (response: SubscriptionResponse) =>
                HandleSubscriptionResponse(
                    response,
                    subscriptionUpdatedCallback,
                    tries
                )
        )
        CheckIfUserHasValidSubscriptionInDatabase(dbRef, user).then(
            (response: SubscriptionResponse) =>
                HandleSubscriptionResponse(
                    response,
                    subscriptionUpdatedCallback,
                    tries
                )
        )
    }, 0)
}

/**
 *
 * @param dbref
 * @param user
 * @returns
 */
const CheckIfUserHasValidSubscriptionInDatabase = async (
    dbref,
    user
): Promise<SubscriptionResponse> => {
    let valid = false
    let message = ""
    const subscriptions = await dbref.fetch(`users/${user.id}/subscriptions`, {
        context: this,
        asArray: false
    })
    if (subscriptions) {
        await fetch(`${functionsURL}/checkIfUserHasActiveSubscriptionInDB`, {
            body: JSON.stringify({
                user: user.uid,
                now: Date.now()
            }),
            method: "POST"
        })
            .then(async (res) => {
                return await res.json()
            })
            .then(async (json) => {
                if (json.success) {
                    if (json.subscribed) {
                        console.log(
                            "Found a valid subscription in the database"
                        )
                    }
                    valid = json.subscribed
                } else {
                    valid = false
                }
            })
            .catch((err) => {
                valid = false
                message = "Error validating subscription."
            })

        return {
            valid,
            message
        }
    }
}

/**
 *
 * @param user
 * @returns
 */
const CheckIfUserHasValidSubscriptionStatus = async (
    user
): Promise<SubscriptionResponse> => {
    let valid = false
    if (user?.subscriptionStatus) {
        valid = shouldBypassSubscriptionCheck(user.subscriptionStatus)
    }
    return {
        valid,
        message: ""
    }
}

/**
 *
 * @param response
 * @param subscriptionUpdatedCallback
 * @param max
 * @param tries
 */
const HandleSubscriptionResponse = (
    response: SubscriptionResponse,
    subscriptionUpdatedCallback: (valid: boolean) => void,
    tries: {
        max: number
        current: number
        calledBack: boolean
    }
) => {
    if (response.valid) {
        if (!tries.calledBack) {
            if (subscriptionUpdatedCallback && !tries.calledBack) {
                subscriptionUpdatedCallback(true)
                tries.calledBack = true
            }
        }
        // hasValidSubscription = true
    }
    tries.current += 1
    if (tries.current === tries.max && !tries.calledBack) {
        subscriptionUpdatedCallback(false)
        tries.calledBack = true
    }
}
