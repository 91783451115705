import { FontWeightProperty } from "csstype"
import { Colors, DefaultColors } from "../../util/colors"

export default {
    container: {},
    button: {
        padding: "10px 20px",
        backgroundColor: Colors.green,
        display: "inline-block" as "inline-block",
        cursor: "pointer" as "pointer",
        borderRadius: 3,
        borderWidth: 0
    },
    buttonContent: {
        display: "flex",
        alignItems: "center" as "center",
        justifyContent: "center" as "center"
    },
    loadingContainer: {
        marginLeft: 16
    },
    label: {
        fontSize: 14,
        fontWeight: 400 as FontWeightProperty,
        color: Colors.white
    },
    loading: {
        pointerEvents: "none" as "none",
        opacity: 0.75
    },
    disablePointerEvents: {
        pointerEvents: "none" as "none"
    },
    disabled: {
        backgroundColor: DefaultColors.gray[500]
    },
    disabledText: {
        textShadow: `0px 1px 3px ${DefaultColors.gray[500]}`
    }
}
