import { DefaultColors } from "util/colors"
export default {
    container: {
        position: "relative" as "relative",
        height: 38,
        alignItems: "center",
        display: "flex"
    },
    popup: {
        // borderRadius: 3,
        // padding: 12,
        // position: "absolute" as "absolute",
        // top: 0,
        // right: 0
        // width: 500
    },
    title: {
        fontSize: 16,
        fontWeight: 700,
        color: DefaultColors.gray[700],
        display: "block",
        marginBottom: 12
    },
    close: {
        position: "absolute" as "absolute",
        top: 15,
        right: 15,
        cursor: "pointer"
    },
    iconContainer: {
        flex: 1,
        alignItems: "center",
        display: "flex",
        cursor: "pointer" as "pointer"
    },
    filters: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "flex-start"
    },
    checkboxInput: {
        display: "flex",
        flexDirection: "row-reverse" as "row-reverse",
        paddingTop: 4,
        paddingBottom: 4,
        alignItems: "center"
    },
    checkbox: {
        marginRight: 8
    },
    label: {
        fontSize: 13
    },
    activeDateRange: {
        fontSize: 13,
        color: DefaultColors.gray[600],
        display: "block" as "block",
        position: "relative" as "relative",
        top: 2
    }
}