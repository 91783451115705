import * as React from "react"
import styles from "./styles"

type Props = {
    label: string
    value: string
    type: "email" | "text" | "tel" | "hidden" | "password" | "datetime-local"
    onChange: (value: string) => void
    testId?: string
    inline?: boolean
    placeholder?: string
    onBlur?: () => void
    onFocus?: () => void
    style?: any
    containerStyle?: any
    disabled?: boolean
    units?: string
}

const Input: React.StatelessComponent<Props> = (props: Props) => (
    <div
        style={Object.assign(
            {},
            styles.container,
            props.containerStyle,
            props.inline && styles.inline
        )}
    >
        <label
            style={Object.assign(
                {},
                styles.label,
                !props.inline && { marginBottom: 8 }
            )}
        >
            {props.label}
        </label>
        <div style={styles.inputContainer}>
            <input
                data-testid={props.testId}
                style={Object.assign(
                    {},
                    styles.input,
                    styles[props.type],
                    props.units && props.units !== "" && styles.inputWithUnits,
                    props.style
                )}
                value={props.value}
                type={props.type}
                onChange={(e) => props.onChange(e.target.value)}
                placeholder={props.placeholder}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                disabled={props.disabled}
            />
            {props.units && props.units !== "" && (
                <div style={styles.unitsContainer}>
                    <span style={styles.unit}>{props.units}</span>
                </div>
            )}
        </div>
    </div>
)

Input.defaultProps = {
    testId: "input"
}

export default Input
