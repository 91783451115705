import ForgotPassword from "components/forgot-password-form"
import * as React from "react"
import { withAppContext } from "../../Context"
import styles from "./styles"

type Props = {
    requestResetPassword: (email: string) => void
}

const ForgotPasswordScreen = (props: Props) => (
    <div style={styles.container}>
        <ForgotPassword />
    </div>
)

export default withAppContext(ForgotPasswordScreen)
