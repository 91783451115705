import * as React from "react"
import { DefaultColors } from "util/colors"
import styles from "./styles"

type Props = {}
type State = {}

const quotes = [{
    text: "Love the way this app is set up! Have all your runs, conditions, and notes in one spot at all times! I was skeptical on the et predictor, but once you figure out which option best connects with your vehicle its pretty close! Also, I recently obtained a new device and I had great customer service to transfer over! I definitely would recommend this app!",
    name: "Cody C."
}, {
    text: "I started using this app at the beginning of the race season. The ET prediction is spot-on and the weather condition info is amazingly detailed!🏁 (I'm currently 2nd in Pro ET points at South Mountain Raceway)",
    name: "Robert H."
}]

export default (props: Props) => {
    return (
        <div style={styles.container}>
            <p style={styles.heading}>Listen to our customers...</p>
            {quotes.map((q, index) => {
                return (
                    <div style={styles.quoteContainer} key={index}>
                        <svg style={styles.quoteIcon} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path style={styles.quoteIconPath} d="M15.264 19.552C15.264 23.2 17.664 25.12 20.352 25.12C23.328 25.12 26.112 22.624 26.112 19.456C26.112 16.864 24.288 15.136 22.08 15.136C21.888 15.136 21.408 15.136 21.312 15.136C22.368 12.064 25.824 8.8 29.376 7.072L26.4 4C20.448 6.976 15.264 13.504 15.264 19.552ZM0 19.552C0 23.2 2.304 25.12 5.088 25.12C8.064 25.12 10.848 22.624 10.848 19.456C10.848 16.864 8.928 15.136 6.72 15.136C6.528 15.136 6.048 15.136 5.952 15.136C7.008 12.064 10.56 8.8 14.016 7.072L11.136 4C5.184 6.976 0 13.504 0 19.552Z"></path>
                        </svg>
                        <span style={styles.quoteText}>{q.text}</span>
                        <span style={styles.quoteAuthor}>- {q.name}</span>
                    </div>
                )
            })
            }
        </div>
    )
}
