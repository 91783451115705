import { Colors, DefaultColors } from "util/colors"
export default {
    menuItem: {
        flex: 1,
        listStyleType: "none" as "none",
        paddingTop: 8,
        paddingBottom: 8,
        marginRight: 24
    },
    link: {
        color: DefaultColors.gray[200],
        fontSize: 15,
        fontWeight: 600 as 600,
        display: "flex",
        alignItems: "center" as "center",
        // textTransform: "uppercase" as "uppercase",
        letterSpacing: .6
    },
    icon: {
        marginRight: 24,
        color: "#313131"
    },
    activeLink: {
        color: DefaultColors.gray[200],
        fontWeight: 600 as 600
    },
    activeMenuItem: {
        borderBottomWidth: 4,
        borderBottomStyle: "solid",
        borderBottomColor: DefaultColors.red[700],
        paddingBottom: 8
    }
}
