export default {
    container: {
        width: "100%",
        // display: "flex",
        flexDirection: "column" as "column",
        display: "flex",
        flex: 1
        // height: "100%",
    },
    main: {
        // display: "flex",
        // height: "100%",
        // overflow: "hidden" as "hidden",
        // overflowY: "scroll" as "scroll",
        backgroundColor: "#f9f9f9",
        display: "flex",
        flex: 1
    },
    content: {
        // flex: 1,
        padding: 24,
        // borderLeft: "2px solid #e4e4e4"
        // borderTop: "2px solid #e4e4e4"
        display: "flex",
        flex: 1,
    }
}
