import { DefaultColors } from "util/colors"
import {
    RunFilterField,
    RunField,
    SingleCondition,
    WeatherReturn
} from "types/index"

export const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const fieldToLabel = (field: string) => {
    return capitalizeFirstLetter(field)
}

export const labelToField = (label: string): RunFilterField => {
    switch (label) {
        case "Event":
            return "event"
        case "Track":
            return "track"
        case "Lane":
            return "lane"
        case "Length":
            return "length"
        default:
            return label as RunFilterField
    }
}

export const getColorForField = (field: string) => {
    switch (field) {
        case "event":
            return DefaultColors.blue
        case "track":
            return DefaultColors.green
        case "length":
            return DefaultColors.orange
        case "lane":
            return DefaultColors.purple
        default:
            return DefaultColors.blue
    }
}

export const getTransformedKey = (
    key: string
): { key: string; type: string } => {
    switch (key) {
        case "event":
        case "track":
        case "lane":
        case "session":
            return { key: `timeslip.${key}`, type: "string" }
        case "reactionTime":
        case "sixty":
        case "threeThirty":
        case "sixSixty":
        case "sixSixtyMph":
        case "thousand":
        case "thirteenTwenty":
        case "thirteenTwentyMph":
            return { key: `timeslip.${key}`, type: "number" }
        default:
            return { key, type: "string" }
    }
}

export const SubscriptionTypes = {
    NOT_SUBSCRIBED: "0",
    STORE: "1",
    GIVEAWAY: "2",
    ADMIN: "3"
}

export type Subscription = {
    status: string
    expireDate: number
}

export const shouldBypassSubscriptionCheck = (subscription: Subscription) => {
    if (subscription) {
        const { status, expireDate } = subscription
        let now = Date.now()
        if (status) {
            if (status === SubscriptionTypes.GIVEAWAY) {
                if (expireDate) {
                    if (expireDate > now) {
                        return true
                    } else {
                        return false
                    }
                }
                return false
            }
            if (status === SubscriptionTypes.ADMIN) {
                return true
            }
        } else {
            return false
        }
    } else {
        return false
    }
}

export const getLabelForField = (field: RunField | SingleCondition) => {
    switch (field) {
        case "date":
            return "Date"
        case "event":
            return "Event"
        case "session":
            return "Session"
        case "track":
            return "Track"
        case "length":
            return "Length"
        case "lane":
            return "Lane"
        case "reactionTime":
            return "R/T"
        case "sixty":
            return "60'"
        case "threeThirty":
            return "330'"
        case "sixSixty":
            return "1/8"
        case "sixSixtyMph":
            return "1/8 MPH"
        case "thousand":
            return "1000'"
        case "thirteenTwenty":
            return "1/4"
        case "thirteenTwentyMph":
            return "1/4 MPH"
        case "trackTemp":
            return "Track Temperature"
        case "notes":
            return "Notes"
        case "estimatedET":
            return "Estimated ET"
        case "dialIn":
            return "Dial In"
        case "delay":
            return "Delay"
        case "tirePressure":
            return "Tire Pressure"
        case "densityAltitude":
            return "Density Altitude"
        case "stdCorrectionFactor":
            return "STD Correction Factor"
        case "barometer":
            return "Barometer"
        case "temperature":
            return "Temperature"
        case "waterGrains":
            return "Water Grains"
        case "humidity":
            return "Humidity"
        case "dewPoint":
            return "Dew Point"
        case "adr":
            return "ADR"
        case "pressureAltitude":
            return "Pressure Altitude"
        case "saeCorrectionFactor":
            return "SAE Correction Factor"
        case "uncorrectedBarometer":
            return "Uncorrected Barometer"
        case "provider":
            return "Provider"
        case "vaporPressure":
            return "Vapor Pressure"
        case "wetBulb":
            return "Wet Bulb"
        case "windspeed":
        case "windSpeed":
            return "Wind Speed"
        case "winddirection":
        case "windDirection":
            return "Wind Direction"
        default:
            if (field !== "") {
                console.log("Field missing from getLabel: ", field)
            }
            return field
        // Do Nothing
    }
}

export const generateNewRun = () => {
    const run = {
        timeslip: {
            event: "",
            session: "",
            track: "",
            date: new Date().getTime(),
            length: "1/8" as "1/8",
            lane: "L" as "L",
            reactionTime: "",
            sixty: "",
            threeThirty: "",
            sixSixty: "",
            sixSixtyMph: "",
            thousand: "",
            thirteenTwenty: "",
            thirteenTwentyMph: ""
        },
        conditions: {
            weather: {
                temperature: "",
                densityAltitude: "",
                humidity: "",
                barometer: "",
                dewPoint: "",
                waterGrains: "",
                wind: {
                    speed: "",
                    direction: ""
                }
            },
            trackTemp: "",
            notes: ""
        },
        details: {
            estimatedET: "",
            dialIn: "",
            delay: "",
            tirePressure: "",
            notes: ""
        },
        vehicle: null,
        includeInETPrediction: true
    }

    return run
}

export const weatherReturnToState = (weather: WeatherReturn) => {
    return {
        temperature: weather.temperature,
        humidity: weather.humidity,
        barometer: weather.barometer,
        dewPoint: weather.dewPoint,
        densityAltitude: weather.densityAltitude,
        stdCorrectionFactor: weather.stdCorrectionFactor,
        saeCorrectionFactor: weather.saeCorrectionFactor,
        adr: weather.adr,
        vaporPressure: weather.vaporPressure,
        wetBulb: weather.wetBulb,
        waterGrains: weather.waterGrains,
        pressureAltitude: weather.pressureAltitude,
        windSpeed: weather.wind.speed,
        windDirection: weather.wind.direction,
        provider: weather.provider
    }
}

export const weatherKeysToRender = () => {
    return [
        "temperature",
        "humidity",
        "barometer",
        "dewPoint",
        "densityAltitude",
        "stdCorrectionFactor",
        "saeCorrectionFactor",
        "adr",
        "vaporPressure",
        "wetBulb",
        "waterGrains",
        "pressureAltitude",
        "wind"
    ]
}

export const getUnitForField = (field: SingleCondition) => {
    switch (field) {
        case "barometer":
            return "IN"
        case "temperature":
            return "°"
        case "humidity":
            return "%"
        case "dewPoint":
            return "°"
        case "windSpeed":
            return "MPH"
        case "densityAltitude":
            return "ft"
        case "waterGrains":
            return "g/lb"
        default:
            return ""
    }
}
