import Colors from "../../colors"

export default {
    deleteButton: {
        backgroundColor: Colors.red[600],
        transition: "all .25s"
    },
    cancelButton: {
        backgroundColor: Colors.gray[300],
        transition: "all .25s"
    },
    cancelButtonText: {
        color: Colors.gray[700]
    },
    deleteButton_hover: {
        backgroundColor: Colors.red[700]
    },
    cancelButton_hover: {
        backgroundColor: Colors.gray[400]
    }
}
