import { Colors } from "../../util/colors"

export default {
    container: {
        borderRadius: 3,
        backgroundColor: "#ffffff",
        display: "inline-flex",
        width: "100%",
        flexDirection: "column" as "column",
        boxShadow: "0px 2px 40px rgba(0,0,0,.05)"
    },
    heading: {
        margin: 0,
        fontWeight: 600 as 600,
        color: "#f9f9f9",
        fontSize: 14
    },
    header: {
        // backgroundColor: "#ededed",
        // backgroundColor: Colors.red,
        padding: 16,
        // borderTopRightRadius: 3,
        // borderTopLeftRadius: 3,
        display: "flex",
        justifyContent: "space-between" as "space-between",
        alignItems: "center" as "center"
    },
    refreshButton: {
        cursor: "pointer",
        color: "#ededed"
    },
    body: {},
    row: {
        display: "flex",
        flexDirection: "row" as "row",
        justifyContent: "space-between",
        alignItems: "center" as "center",
        borderBottom: "1px solid rgba(0,0,0,.05)",
        paddingLeft: 16,
        paddingRight: 16,
        height: 48
    },
    label: {
        color: "#444444",
        fontSize: 12
    },
    value: {
        color: "#212121",
        fontSize: 14,
        fontWeight: 500 as 500
    }
}
