import * as React from "react"
import styles from "./styles"

type Props = {
    label: string
    value: string | number
    unit: string
}

export default (props: Props) => (
    <div style={styles.row}>
        <p style={styles.label}>{props.label}</p>
        <p style={styles.value}>
            {props.value ? `${props.value} ${props.unit}` : "-"}
        </p>
    </div>
)
