import { Link } from "@reach/router"
import * as React from "react"
import styles from "./styles"

type Props = {
    to: string
    text: string
    icon?: string
    active?: boolean
}

export default (props: Props) => (
    <li
        style={Object.assign(
            {},
            styles.menuItem,
            props.active && styles.activeMenuItem
        )}
    >
        <Link
            style={Object.assign(
                {},
                styles.link,
                props.active && styles.activeLink
            )}
            to={props.to}
        >
            {/* <img style={styles.icon} src={props.icon} /> */}
            {props.text}
        </Link>
    </li>
)
