import AppContext, { Context } from "context"
import moment from "moment"
import * as React from "react"
import Select from "react-select"
import { DefaultColors } from "util/colors"
const { useContext, useRef } = React
import { RunFilterField } from "types/index"
import ActiveFilters from "./active"
import DateRange from "./date-range"
import ExtraFilters from "./extra"
import styles from "./styles"

export default () => {
    const { setFilter, resetFilters, state } = useContext<Context>(AppContext)
    const { runs } = state
    const EventSelect = useRef(null)
    const TrackSelect = useRef(null)
    const LaneSelect = useRef(null)
    const LengthSelect = useRef(null)
    const customStyles = {
        input: (provided) => ({
            ...provided,
            fontSize: 13,
            width: 150
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: 13,
            color: DefaultColors.gray[800]
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: 13
        }),
        option: (provided) => ({
            ...provided,
            fontSize: 13,
            cursor: "pointer" as "pointer"
        })
    }
    const onSetFilter = (field, values, { action }, multi = true) => {
        if (action === "clear") {
            resetFilters()
        } else if (action === "remove-value") {
            setFilter(field, values)
        } else {
            let filterValues
            if (multi) {
                filterValues = values.map((f) => f.value)
                if (field === "date") {
                    filterValues = filterValues.map((v) => {
                        return moment(v).format("YYYY-MM-DD HH:mm:ss")
                    })
                }
            } else {
                filterValues = [values.value]
            }
            setFilter(field, filterValues)
        }
    }
    const formatLabel = (field: RunFilterField, value: any) => {
        switch (field) {
            case "startDate":
                return moment(value).format("MMMM DD, YYYY HH:mm:ss")
            case "endDate":
                return moment(value).format("MMMM DD, YYYY HH:mm:ss")
            default:
                return value
        }
    }
    const getOptions = (field: RunFilterField) => {
        if (runs) {
            const availableKeys = new Set()
            runs.forEach((error) => {
                if (error["timeslip"][field]?.value) {
                    console.log(error)
                    availableKeys.add(error["timeslip"][field].value)
                } else {
                    availableKeys.add(error["timeslip"][field])
                }
            })
            // console.log(runs)
            return Array.from(availableKeys)
                .sort()
                .map((key) => ({
                    label: formatLabel(field, key),
                    value: formatLabel(field, key)
                }))
                .filter((k) => k.label !== "" || k.value !== "")
        }
        return []
    }
    const clearSelects = () => {
        TrackSelect.current.select.clearValue()
        EventSelect.current.select.clearValue()
        LengthSelect.current.select.clearValue()
        LaneSelect.current.select.clearValue()
    }
    const clearSingle = (filter: RunFilterField, value: string) => {
        if (filter === "track") {
            const values = TrackSelect.current.select.commonProps
                .getValue()
                .filter((v) => v.value !== value)
            TrackSelect.current.select.setValue(values)
            setFilter(
                filter,
                values.map((v) => v.value)
            )
        } else if (filter === "event") {
            const values = EventSelect.current.select.commonProps
                .getValue()
                .filter((v) => v.value !== value)
            EventSelect.current.select.setValue(values)
            setFilter(
                filter,
                values.map((v) => v.value)
            )
        } else if (filter === "lane") {
            const values = LaneSelect.current.select.commonProps
                .getValue()
                .filter((v) => v.value !== value)
            LaneSelect.current.select.setValue(values)
            setFilter(
                filter,
                values.map((v) => v.value)
            )
        } else if (filter === "length") {
            const values = LengthSelect.current.select.commonProps
                .getValue()
                .filter((v) => v.value !== value)
            LengthSelect.current.select.setValue(values)
            setFilter(
                filter,
                values.map((v) => v.value)
            )
        }
    }
    return (
        <div style={styles.container}>
            <div style={styles.row}>
                <div style={styles.left}>
                    <div style={styles.planeSelect}>
                        <Select
                            classNamePrefix="dashboard-filter-react-select"
                            ref={EventSelect}
                            styles={customStyles}
                            options={getOptions("event")}
                            onChange={(value, action) =>
                                onSetFilter("event", value, action)
                            }
                            placeholder="Select Event..."
                            isClearable={false}
                            isMulti
                            controlShouldRenderValue={false}
                        />
                    </div>
                    <div style={styles.planeSelect}>
                        <Select
                            classNamePrefix="dashboard-filter-react-select"
                            ref={TrackSelect}
                            styles={customStyles}
                            options={getOptions("track")}
                            onChange={(value, action) =>
                                onSetFilter("track", value, action)
                            }
                            placeholder="Select Track..."
                            isClearable={false}
                            isMulti
                            controlShouldRenderValue={false}
                        />
                    </div>
                    <div style={styles.planeSelect}>
                        <Select
                            classNamePrefix="dashboard-filter-react-select"
                            ref={LaneSelect}
                            styles={customStyles}
                            options={getOptions("lane")}
                            onChange={(value, action) =>
                                onSetFilter("lane", value, action, false)
                            }
                            placeholder="Select Lane..."
                            isClearable={false}
                            isMulti={false}
                            // controlShouldRenderValue={false}
                        />
                    </div>
                    <div style={styles.planeSelect}>
                        <Select
                            classNamePrefix="dashboard-filter-react-select"
                            ref={LengthSelect}
                            styles={customStyles}
                            options={getOptions("length")}
                            onChange={(value, action) =>
                                onSetFilter("length", value, action, false)
                            }
                            placeholder="Select Track Length..."
                            isClearable={false}
                            isMulti={false}
                            // controlShouldRenderValue={false}
                        />
                    </div>
                    <div style={styles.dateRange}>
                        <DateRange />
                    </div>
                </div>
            </div>
            <ActiveFilters
                onClearFilters={clearSelects}
                onClearSingle={clearSingle}
            />
        </div>
    )
}
