import * as React from "react"
import { DashboardLayout as Layout } from "../../layouts"
import { withAppContext, Context } from "../../Context"

type Props = {
    context: Context
}
type State = {}

class Settings extends React.Component<Props, State> {
    render() {
        return (
            <Layout>
                <div style={{ width: "100%" }}>
                    <h1>Settings</h1>
                </div>
            </Layout>
        )
    }
}

export default withAppContext(Settings)
export { Settings }
