import { DefaultColors } from "util/colors"

export default {
    container: {
        display: "flex",
        alignItems: "center" as "center",
        padding: "12px 16px",
        border: `1px solid ${DefaultColors.gray[300]}`,
        borderRadius: 4
    },
    selectedContainer: {},
    label: {
        marginLeft: 12,
        color: DefaultColors.gray[500]
    },
    left: {
        display: "flex",
        alignItems: "center" as "center"
    },
    right: {
        display: "flex",
        alignItems: "center" as "center"
    }
}
