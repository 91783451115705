import { Link } from "@reach/router"
import * as React from "react"
import Loading from "react-loading"
import styles from "./styles"

type Props = {
    onClick?: (
        event:
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void
    label: string
    testId?: string
    loading?: boolean
    style?: any
    textStyle?: any
    to?: string
    disabled?: boolean
    type?: string
    hoverStyle?: any
    hoverTextStyle?: any
}

const Button: React.StatelessComponent<Props> = (props: Props) => {
    const [hover, setHover] = React.useState(false)
    if (props.to) {
        return (
            <div style={styles.container}>
                <Link
                    data-testid={props.testId}
                    to={props.to}
                    style={Object.assign(
                        {},
                        styles.button,
                        props.style,
                        hover && props.hoverStyle,
                        props.loading && styles.loading
                    )}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <div style={styles.buttonContent}>
                        <p
                            style={Object.assign(
                                {},
                                styles.label,
                                props.textStyle
                            )}
                        >
                            {props.label}
                        </p>
                        {props.loading && (
                            <div style={styles.loadingContainer}>
                                <Loading
                                    type="bubbles"
                                    color="#ffffff"
                                    width="16"
                                    height="16"
                                />
                            </div>
                        )}
                    </div>
                </Link>
            </div>
        )
    } else if (props.type === "submit") {
        return (
            <button
                data-testid={props.testId}
                type="submit"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                style={Object.assign(
                    {},
                    styles.button,
                    props.style,
                    hover && props.hoverStyle,
                    props.loading && styles.loading,
                    props.disabled && styles.loading
                )}
            >
                {/* <div style={Object.assign({}, styles.buttonContent)}> */}
                <p style={Object.assign({}, styles.label, props.textStyle)}>
                    {props.label}
                </p>
                {props.loading && (
                    <div style={styles.loadingContainer}>
                        <Loading
                            type="bubbles"
                            color="#ffffff"
                            width="16"
                            height="16"
                        />
                    </div>
                )}
                {/* </div> */}
            </button>
        )
    } else {
        return (
            <div style={styles.container}>
                <a
                    data-testid={props.testId}
                    onClick={props.onClick}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    style={Object.assign(
                        {},
                        styles.button,
                        props.style,
                        hover && props.hoverStyle,
                        props.loading && styles.loading,
                        props.disabled && styles.loading,
                        props.disabled && styles.disabled
                    )}
                    title="Select at least 3 runs"
                >
                    <div style={styles.buttonContent}>
                        <p
                            style={Object.assign(
                                {},
                                styles.label,
                                props.textStyle,
                                hover && props.hoverTextStyle,
                                props.disabled && styles.disabledText
                            )}
                        >
                            {props.label}
                        </p>
                        {props.loading && (
                            <div style={styles.loadingContainer}>
                                <Loading
                                    type="bubbles"
                                    color="#ffffff"
                                    width="16"
                                    height="16"
                                />
                            </div>
                        )}
                    </div>
                </a>
            </div>
        )
    }
}

Button.defaultProps = {
    testId: "button"
}

export default Button
