import * as React from "react"
import styles from "./styles"

type Props = {
    requestHide: () => void
    actions?: any
    title: string
    subtitle?: string
}

export default (props: Props) => {
    const handleClick = e => {
        if (e.target.id === "popup-overlay") {
            props.requestHide()
        }
    }
    return (
        <div id="popup-overlay" style={styles.popup} onClick={handleClick}>
            <div id="inner-popup" style={styles.inner}>
                <p style={styles.title}>{props.title}</p>
                <p style={styles.subTitle}>{props.subtitle}</p>
                <div style={styles.actions}>{props.actions}</div>
            </div>
        </div>
    )
}
