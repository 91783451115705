import * as React from "react"
import { firebaseApp, firebaseDB } from "util/init-firebase"
import { Provider } from "./Context"
const clonedeep = require("lodash.clonedeep")
import * as SubscriptionHelpers from "./util/subscription-helpers"

import {
    RunFilterField,
    RunFilterType,
    RunType,
    VehicleType
} from "types/index"

type Props = {}

export type State = {
    loading?: boolean
    user?: firebase.User
    selectedVehicle?: { id: string; name: string }
    vehicles?: VehicleType[]
    runs?: RunType[]
    selectedRuns: string[]
    tracks?: string[]
    events?: string[]
    selectedVehicles?: string[]
    filters?: RunFilterType
    userInfo?: {
        creationTime?: string
        email: string
        emailVerified: boolean
        id: string
        lastSignInTime?: string
        name: string
        rate: number
        settings: {
            timeslipDistanceUnits: string
            timeslipSpeedUnits: string
            weatherProvider: string
            weatherUnits: string
        }
        stripe: {
            customer: string
        }
        subscribed: boolean
        subscriptionStatus?: { status?: string; expireDate?: number }
    }
    activeSubscription?: boolean
    checkingSubscriptions?: boolean
}

export default class AppProvider extends React.Component<Props, State> {
    _userListener
    state = {
        loading: true,
        user: undefined,
        selectedVehicle: null,
        vehicles: null,
        runs: null,
        selectedRuns: [],
        selectedVehicles: [],
        filters: {
            length: [],
            track: [],
            event: [],
            startDate: null,
            endDate: null,
            order: "DESC" as "DESC",
            sortBy: "date" as "date",
            lane: []
        },
        userInfo: null,
        activeSubscription: false,
        checkingSubscriptions: true
    }
    componentDidMount() {
        firebaseApp.auth().onAuthStateChanged(this.authListener)
        this.syncVehiclesToState()
        this.syncRunsToState()
        this.syncTracksToState()
        this.syncEventsToState()
        const selectedVehicle = localStorage.getItem("selectedVehicle")
        if (selectedVehicle && selectedVehicle !== "") {
            this.setState({ selectedVehicle: JSON.parse(selectedVehicle) })
        }
    }
    componentWillUnmount() {
        this.destroyUserListener()
    }
    setUpUserListener = () => {
        const { user } = this.state
        this._userListener = firebaseDB.listenTo(`users/${user.uid}`, {
            context: this,
            asArray: false,
            then: (userInfo) => {
                console.log("setting userinfo: ", userInfo)
                // Need to do sub checks here as well.
                this.setState({ userInfo })
            }
        })
    }
    destroyUserListener = () => {
        if (this._userListener) {
            firebaseDB.removeBinding(this._userListener)
            this._userListener = null
        }

        // This was previous, doesn't work because removeBinding isn't on the listener itself
        // if (this._userListener && this._userListener.removeBinding) {
        //     this._userListener.removeBinding()
        // }
    }
    authListener = (user: any) => {
        if (user) {
            this.setState({ user })
            this.toggleLoading(true)
            firebaseDB
                .fetch(`users/${user.uid}`, {
                    context: this,
                    asArray: false
                })
                .then((u) => {
                    this.checkIfUserHasSubscription(u)
                    if (u && JSON.stringify(u) !== JSON.stringify({})) {
                        firebaseDB.update(`users/${user.uid}`, {
                            data: {
                                emailVerified: user.emailVerified,
                                lastSignInTime: new Date().toUTCString()
                            }
                        })
                    } else {
                        firebaseDB
                            .post(`users/${user.uid}`, {
                                data: {
                                    id: user.uid,
                                    name: user.displayName,
                                    subscribed: false,
                                    email: user.email,
                                    emailVerified: user.emailVerified,
                                    creationTime: user.metadata.creationTime,
                                    lastSignInTime: new Date().toUTCString()
                                }
                            })
                            .then(() => {
                                this.toggleLoading(false)
                            })
                    }
                })
                .then(() => {
                    this.toggleLoading(false)
                })
        } else {
            this.toggleLoading(false)
        }
        this.setState(
            {
                user
            },
            () => {
                if (user && user.emailVerified) {
                    this.setUpUserListener()
                }
            }
        )
    }
    setActiveVehicle = (vehicleId: string) => {
        const { name, id } = this.state.vehicles.find((v) => v.id === vehicleId)
        this.setState({ selectedVehicle: { name, id } })
        localStorage.setItem("selectedVehicle", JSON.stringify({ name, id }))
    }
    checkIfUSerHasSubscriptionInDatabase = (user: any) => {
        console.log(user.id)
        return fetch(
            `https://us-central1-dragtuner-de058.cloudfunctions.net/checkIfUserHasActiveSubscriptionInDB`,
            {
                method: "POST",
                body: JSON.stringify({ user: user.id })
            }
        )
            .then((res) => res.json())
            .catch((err) => {
                console.error(err)
                return { success: false }
            })
    }
    createStripeSession = (user) => {
        // console.log(JSON.stringify({ customer: user.stripe.customer }))
        return fetch(
            `https://us-central1-dragtuner-de058.cloudfunctions.net/stripe-createSession`,
            {
                method: "POST",
                body: JSON.stringify({ custome: user.stripe.customer })
            }
        )
            .then((d) => d.json())
            .catch((e) => {
                console.error(e)
                return { success: false }
            })
    }
    createStripeCustomer = (user: any) => {
        return fetch(
            `https://us-central1-dragtuner-de058.cloudfunctions.net/stripe-createCustomer`,
            {
                method: "POST",
                body: JSON.stringify({ email: user.email })
            }
        )
            .then((d) => d.json())
            .catch((e) => {
                console.error(e)
                return { success: false }
            })
    }
    checkIfUserHasSubscription = async (user) => {
        this.setState({ loading: true })
        const subscriptionUpdatedCallback = (success) => {
            if (this.state.loading) {
                this.setState({ loading: false })
            }
            if (!this.state.activeSubscription && success) {
                this.setState({ activeSubscription: success })
            }
            if (this.state.checkingSubscriptions) {
                this.setState({ checkingSubscriptions: false })
            }
        }
        await SubscriptionHelpers.CheckIfUserHasValidSubscription(
            firebaseDB,
            user,
            subscriptionUpdatedCallback
        )
        // const shouldBypassCheck = shouldBypassSubscriptionCheck(
        //     user.subscriptionStatus
        // )
        // if (!shouldBypassCheck) {
        //     const active = await this.checkIfUSerHasSubscriptionInDatabase(user)
        //     if (active.success && !active.subscribed) {
        //         if (!user?.stripe?.customer) {
        // const customer = await this.createStripeCustomer(user)
        // firebaseDB.update(`users/${user.id}`, {
        //     data: {
        //         stripe: {
        //             customer: customer.id
        //         }
        //     }
        // })
        //     } else {
        //         // const session = await this.createStripeSession(
        //         //     this.state.userInfo
        //         // )
        //         // if (session.url) {
        //         //     // window.location.replace(session.url)
        //         // }
        //     }
        // }
        //     fetch(
        //         ` https://us-central1-dragtuner-de058.cloudfunctions.net/checkIfUserHasActiveSubscriptionInDB `,
        //         {
        //             method: "POST",
        //             body: JSON.stringify({ user: user.id })
        //         }
        //     )
        //         .then((d) => {
        //             return d.json()
        //         })
        //         .then((data) => {
        //             const { success, subscribed } = data
        //             if (!subscribed) {
        //                 fetch(
        //                     `https://us-central1-dragtuner-de058.cloudfunctions.net/stripe-createSession`,
        //                     {
        //                         method: "POST",
        //                         body: JSON.stringify({ customer: "cus_GFycg1BvzRzMlx" })
        //                     }
        //                 )
        //                     .then(d => d.json())
        //                     .then(d => {
        //                         console.log("Session: ", d)
        //                     })
        //                     .catch(e => {
        //                         console.log(e)
        //                     })
        //             }
        //         })
        //         .catch((e) => {
        //             console.log(e)
        //         })
        // }
    }
    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.user !== this.state.user && this.state.user) {
            this.syncVehiclesToState()
            this.syncRunsToState()
            this.syncTracksToState()
            this.syncEventsToState()
        }
    }
    syncVehiclesToState = () => {
        if (this.state.user) {
            const { uid } = this.state.user
            firebaseDB.syncState(`vehicles/${uid}`, {
                context: this,
                state: "vehicles",
                asArray: true,
                then: () => {
                    const v = localStorage.getItem("selectedVehicle")
                    let exists = null
                    let parsed = null
                    if (v) {
                        parsed = JSON.parse(v)

                        exists = this.state.vehicles.find(
                            (vehicle) => parsed.id === vehicle.id
                        )
                    }
                    let selectedVehicle = this.state.vehicles[0]
                    if (exists) {
                        selectedVehicle = parsed
                    }
                    this.setState({ selectedVehicle, loading: false })
                }
            })
        }
    }
    syncRunsToState = () => {
        if (this.state.user) {
            let { uid } = this.state.user
            this.setState({ loading: true })
            firebaseDB.syncState(`runs/${uid}`, {
                context: this,
                state: "runs",
                asArray: true,
                then: () => this.setState({ loading: false })
            })
        }
    }
    syncTracksToState = () => {
        this.setState({ loading: true })
        firebaseDB.syncState(`allTracks`, {
            context: this,
            state: "tracks",
            asArray: true,
            then: () => this.setState({ loading: false })
        })
    }
    syncEventsToState = () => {
        if (this.state.user) {
            let { uid } = this.state.user
            this.setState({ loading: true })
            firebaseDB.syncState(`events/${uid}`, {
                context: this,
                state: "events",
                asArray: true,
                then: () => this.setState({ loading: false })
            })
        }
    }
    handleDeleteRun = (id: string) => {
        const { uid } = this.state.user
        firebaseDB
            .remove(`runs/${uid}/${id}`)
            .then(() => {
                // Do Nothing!
            })
            .catch((e) => console.log("Error deleting the run"))
    }
    logout = () => {
        firebaseApp.auth().signOut()
    }
    toggleLoading = (loading: boolean) => {
        this.setState({ loading })
    }
    selectRun = (ids: string[]) => {
        const { selectedRuns } = this.state
        let copy = [...selectedRuns]
        copy = copy.concat(ids)
        this.setState({ selectedRuns: copy })
    }
    deselectRun = (ids: string[]) => {
        const { selectedRuns } = this.state
        const copy = [...selectedRuns]
        if (ids.length === 0) {
            this.setState({ selectedRuns: [] })
        } else {
            ids.forEach((id) => {
                const index = copy.indexOf(id)
                copy.splice(index, 1)
            })
            this.setState({ selectedRuns: copy })
        }
    }
    selectVehicle = (ids: string[]) => {
        const { selectedVehicles } = this.state
        let copy = [...selectedVehicles]
        copy = copy.concat(ids)
        this.setState({ selectedRuns: copy })
    }
    deselectVehicle = (ids: string[]) => {
        const { selectedVehicles } = this.state
        const copy = [...selectedVehicles]
        if (ids.length === 0) {
            this.setState({ selectedVehicles: [] })
        } else {
            ids.forEach((id) => {
                const index = copy.indexOf(id)
                copy.splice(index, 1)
            })
            this.setState({ selectedVehicles: copy })
        }
    }
    setFilter = (field: RunFilterField, filter: any) => {
        console.log("Setting a filter: ", field, filter)
        const filters = clonedeep(this.state.filters)
        if (!filter) {
            console.log("in the delete")
            delete filters[field]
        } else {
            console.log(filters[field])
            filters[field] = filter
        }
        this.setState({ filters }, () => {
            console.log(this.state.filters)
        })
    }
    resetFilters = () => {
        this.setState({
            filters: {
                length: [],
                track: [],
                event: [],
                startDate: null,
                endDate: null,
                order: "DESC" as "DESC",
                sortBy: "date" as "date",
                lane: []
            }
        })
    }
    render() {
        if (this.state.user !== undefined) {
            return (
                <Provider
                    value={{
                        firebaseApp,
                        firebaseDB,
                        logout: this.logout,
                        state: this.state,
                        toggleLoading: this.toggleLoading,
                        selectRun: this.selectRun,
                        deselectRun: this.deselectRun,
                        deleteRun: this.handleDeleteRun,
                        setFilter: this.setFilter,
                        resetFilters: this.resetFilters,
                        setActiveVehicle: this.setActiveVehicle
                    }}
                >
                    {this.props.children}
                </Provider>
            )
        } else {
            return null
        }
    }
}
