import * as React from "react"
import Row from "./row"
import styles from "./styles"
import { WeatherReturn } from "types/index"
import moment from "moment"

type Props = {
    weather: WeatherReturn
    requestLoad: () => void
    loading: boolean
}

type State = {
    loading: boolean
}

class Weather extends React.Component<Props, State> {
    render() {
        const {
            temperature,
            densityAltitude,
            humidity,
            stdCorrectionFactor,
            saeCorrectionFactor,
            barometer,
            dewPoint,
            wind,
            waterGrains,
            adr,
            vaporPressure,
            wetBulb,
            provider,
            pressureAltitude
        } = this.props.weather
        return (
            <div style={styles.container}>
                <div style={styles.body}>
                    <Row label="Temperature" value={temperature} unit="˚" />
                    <Row label="Humidity" value={humidity} unit="%" />
                    <Row label="Barometer" value={barometer} unit="IN" />
                    <Row label="Dew Point" value={dewPoint} unit="˚" />
                    <Row
                        label="Density Altitude"
                        value={densityAltitude}
                        unit="ft"
                    />
                    <Row
                        label="STD Corrected Factor"
                        value={stdCorrectionFactor}
                        unit=""
                    />
                    <Row
                        label="SAE Corrected Factor"
                        value={saeCorrectionFactor}
                        unit=""
                    />
                    <Row label="ADR" value={adr} unit="˚" />
                    <Row label="Vapor Pressure" value={vaporPressure} unit="" />
                    <Row label="Wet Bulb" value={wetBulb} unit="" />
                    <Row label="Water Grains" value={waterGrains} unit="g/lb" />
                    <Row
                        label="Pressure Altitude"
                        value={pressureAltitude}
                        unit=""
                    />
                    <Row label="Wind Speed" value={wind?.speed} unit="MPH" />
                    <Row
                        label="Wind Direction"
                        value={wind?.direction}
                        unit=""
                    />
                </div>
            </div>
        )
    }
}

Weather.defaultProps = {
    weather: {
        temperature: 0,
        densityAltitude: 0,
        humidity: 0,
        stdCorrectionFactor: 0,
        saeCorrectionFactor: 0,
        barometer: 0,
        dewPoint: 0,
        wind: {
            speed: 0
        },
        waterGrains: 0
    }
}

export default Weather
