import * as React from "react"
import { ViewMode, RunDetailsType } from "../../../types"
import Input from "../../forms/input"
import styles from "./styles"
import { fieldToLabel, getLabelForField } from "util/helpers"

type Props = {
    details: RunDetailsType
    updateDetails: (details: RunDetailsType) => void
    mode: ViewMode
}

const Details = (props: Props) => {
    const { details } = props
    console.log(details)
    const handleChange = (field: string, value: string) => {
        details[field] = value
        props.updateDetails(details)
    }
    const handleInputChange = () => {}
    if (details) {
        return (
            <div style={styles.container}>
                {Object.keys(props.details).map((key) => {
                    const label = getLabelForField(key)
                    const displayLabel = fieldToLabel(label)
                    return (
                        <Input
                            value={details[key]}
                            label={displayLabel}
                            type="text"
                            onChange={(value) => handleChange(key, value)}
                            style={{ width: "100%" }}
                            disabled={props.mode === "view"}
                            key={key}
                        />
                    )
                })}
            </div>
        )
    } else {
        console.log("Need loader, TODO")
        return null
    }
}

export default Details
