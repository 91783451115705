import Runs from "components/runs"
import * as React from "react"
import { DefaultColors } from "util/colors"
import Button from "../../components/button"
import Popup from "../../components/popup"
import { DashboardLayout as Layout } from "../../layouts"
import { withAppContext, Context } from "../../Context"
import Filters from "./components/filters"
import styles from "./styles"

type Props = {
    context: Context
}
type State = {
    showPopup?: boolean
    runToDelete?: string
}

class Home extends React.Component<Props, State> {
    state = {
        showPopup: false,
        runToDelete: null
    }
    showPopup = (id: string) => {
        this.setState({ showPopup: true, runToDelete: id })
    }
    hidePopup = () => {
        this.setState({ showPopup: false, runToDelete: null })
    }
    confirmDeleteRun = () => {
        this.props.context.deleteRun(this.state.runToDelete)
        this.hidePopup()
    }
    isPredictETDisabled = () => {
        if (
            this.props.context.state.selectedRuns &&
            this.props.context.state.selectedRuns.length >= 3
        ) {
            return false
        }
        return true
    }
    render() {
        return (
            <Layout>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1
                    }}
                >
                    <div style={styles.mainActionsBar}>
                        <div style={styles.mainActionsBarInner}>
                            <div style={styles.filters}>
                                <Filters />
                            </div>
                            <div style={styles.ctaContainer}>
                                <Button
                                    label="Add Run"
                                    textStyle={{
                                        fontWeight: 600,
                                        textShadow: `0px 1px 3px ${DefaultColors.blue[400]}`
                                    }}
                                    hoverStyle={{
                                        // backgroundColor: DefaultColors.blue[400],
                                        boxShadow:
                                            "0 7px 14px rgba(50,50,93,.1),0 3px 6px rgba(0,0,0,.08)",
                                        transform: "translateY(-1px)"
                                    }}
                                    style={{
                                        transition: "all .15s ease",
                                        backgroundColor:
                                            DefaultColors.blue[500],
                                        marginRight: 12,
                                        boxShadow:
                                            "0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)"
                                    }}
                                    to="runs/new"
                                />
                                <Button
                                    label="Predict ET"
                                    disabled={this.isPredictETDisabled()}
                                    textStyle={{
                                        fontWeight: 600,
                                        textShadow: `0px 1px 3px ${DefaultColors.green[500]}`
                                    }}
                                    hoverStyle={{
                                        // backgroundColor:
                                        // DefaultColors.green[500]
                                        boxShadow:
                                            "0 7px 14px rgba(50,50,93,.1),0 3px 6px rgba(0,0,0,.08)",
                                        transform: "translateY(-1px)"
                                    }}
                                    style={{
                                        transition: "all .15s ease",
                                        boxShadow:
                                            "0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)"
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={styles.card}>
                        {this.props.context.state.runs &&
                        this.props.context.state.runs.length ? (
                            <Runs
                                data={this.props.context.state.runs}
                                context={this.props.context}
                                onRequestDelete={this.showPopup}
                            />
                        ) : this.props.context.state.runs &&
                          this.props.context.state.runs.length === 0 ? (
                            <div
                                style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    flex: 1,
                                    flexDirection: "column"
                                }}
                            >
                                <span>No Runs</span>
                                <Button
                                    type="button"
                                    label="Add One Now"
                                    style={{ marginTop: 16 }}
                                    textStyle={{
                                        fontWeight: 500,
                                        textTransform: "",
                                        fontSize: 16
                                    }}
                                ></Button>
                            </div>
                        ) : null}
                    </div>
                    {this.state.showPopup && (
                        <Popup
                            requestHide={this.hidePopup}
                            actions={
                                <React.Fragment>
                                    <Button
                                        label="Cancel"
                                        style={styles.cancelButton}
                                        hoverStyle={styles.cancelButton_hover}
                                        textStyle={styles.cancelButtonText}
                                        onClick={this.hidePopup}
                                    />
                                    <Button
                                        label="Delete"
                                        style={Object.assign(
                                            {},
                                            styles.deleteButton,
                                            {
                                                marginLeft: 8
                                            }
                                        )}
                                        hoverStyle={styles.deleteButton_hover}
                                        onClick={this.confirmDeleteRun}
                                    />
                                </React.Fragment>
                            }
                            title="Are you sure you want to delete this run?"
                            subtitle="This action cannot be undone."
                        />
                    )}
                </div>
            </Layout>
        )
    }
}

export default withAppContext(Home)
export { Home }
