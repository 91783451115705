export default {
    container: {
        marginLeft: 120,
        display: "flex",
        flex: 1
    },
    menu: {
        margin: 0,
        padding: 0,
        display: "flex",
        // flex: 1
    }
}
