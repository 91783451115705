import { Weather } from "@bobbyholtzner/dragtuner-sdk"
import WeatherList from "../../components/weather"
import Button from "components/button"
import * as React from "react"
import { DashboardLayout as Layout } from "../../layouts"
import { withAppContext, Context } from "../../Context"
import styles from "./styles"
import { WeatherReturn } from "types/index"
import moment from "moment"
import { MdRefresh } from "react-icons/md"
import Loader from "react-loading"
import { DefaultColors } from "util/colors"

type Props = {
    context: Context
}
type State = {
    weather: WeatherReturn
    loading: boolean
}

class WeatherRoute extends React.Component<Props, State> {
    state = {
        weather: undefined,
        loading: false
    }
    componentDidMount() {
        const latestWeather = localStorage.getItem("latestWeather")
        try {
            if (latestWeather && latestWeather !== "") {
                const weather = JSON.parse(latestWeather)
                this.setState({ weather })
            }
        } catch (err) {
            // Nothing is really needed here...
            console.warn("Could not load latest weather, must fetch again..")
        }
    }
    requestCurrentLocation = () => {
        this.setState({ loading: true })
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude, altitude, altitudeAccuracy } =
                    position.coords
                let elevation = altitudeAccuracy !== -1 ? altitude : null
                this.handleLocationReturn({
                    latitude,
                    longitude,
                    elevation
                })
            },
            (error) => {
                this.handleLocationReturn(null)
                setTimeout(() => {
                    alert(
                        "Error fetching your location. Please try again later."
                    )
                }, 150)
            },
            { enableHighAccuracy: true, timeout: 20000 }
        )
    }
    handleLocationReturn = async (location: {
        latitude: number
        longitude: number
        elevation: number
    }) => {
        let weatherProviderSetting =
            this.props.context.state.userInfo.settings.weatherProvider
        if (weatherProviderSetting === Weather.WeatherProviders.DARKSKY) {
            weatherProviderSetting = Weather.WeatherProviders.ACCUWEATHER
        }
        const weather = await Weather.GetWeather(
            location,
            (weatherProviderSetting as Weather.WeatherProviderType) ||
                Weather.WeatherProviders.ACCUWEATHER
        )
        localStorage.setItem("latestWeather", JSON.stringify(weather))
        this.setState({ weather, loading: false })
    }
    render() {
        return (
            <Layout>
                <div style={{ width: "100%" }}>
                    {this.state.weather ? (
                        <div style={styles.weatherContainer}>
                            <div style={styles.weatherHeader}>
                                <div style={styles.weatherHeaderText}>
                                    <h2>{this.state.weather.location}</h2>
                                    <span style={styles.lastUpdated}>
                                        Last Updated on{" "}
                                        {moment(
                                            this.state.weather.lastUpdatedStr
                                        ).format("MMMM D, h:mm A")}
                                    </span>
                                </div>
                                {!this.state.loading ? (
                                    <a
                                        style={styles.refreshButton}
                                        onClick={this.requestCurrentLocation}
                                        data-testid="refresh"
                                    >
                                        <MdRefresh
                                            className="fa-spin"
                                            size={20}
                                            fill={DefaultColors.gray[500]}
                                        />
                                    </a>
                                ) : (
                                    <Loader
                                        type="bubbles"
                                        height={16}
                                        width={16}
                                        color={DefaultColors.gray[500]}
                                        data-testid="loading"
                                    />
                                )}
                            </div>
                            <WeatherList
                                weather={this.state.weather}
                                requestLoad={this.requestCurrentLocation}
                                loading={this.state.loading}
                            />
                        </div>
                    ) : (
                        <div style={styles.noWeather}>
                            <span>Weather Not Available</span>
                            <Button
                                onClick={this.requestCurrentLocation}
                                style={{ marginTop: 24 }}
                                label="Get Weather"
                                loading={this.state.loading}
                            />
                        </div>
                    )}
                </div>
            </Layout>
        )
    }
}

export default withAppContext(WeatherRoute)
export { WeatherRoute }
