import * as React from "react"
import { createContext } from "react"
import { State } from "./Provider"
const AppContext = createContext(null)
const { Consumer, Provider } = AppContext
import firebase from "firebase"
import { RunFilterField } from "types/index"

type Context = {
    firebaseApp: firebase.app.App
    firebaseDB: any
    logout: () => void
    state: State
    toggleLoading: (loading: boolean) => void
    selectRun: (id: string[]) => void
    deselectRun: (id: string[]) => void
    deleteRun: (id: string) => void
    selectVehicle: (id: string[]) => void
    deselectVehicle: (id: string[]) => void
    deleteVehicle: (id: string) => void,
    setFilter: (field: RunFilterField, value: any) => void
    resetFilters: () => void
    setActiveVehicle: (vehicleId: string) => void
}

const withAppContext = (Component) => {
    return function WrapperComponent(props) {
        return (
            <Consumer>
                {(context: Context) => (
                    <Component {...props} context={context} />
                )}
            </Consumer>
        )
    }
}

export { Consumer, Provider, Context, withAppContext }
export default AppContext;
