import * as React from "react"
import { DateRangePicker } from "react-dates"
import { Calendar, CheveronDown, Close } from "react-zondicons"
import { DefaultColors } from "util/colors"
import styles from "./styles"
import "react-dates/lib/css/_datepicker.css"
import "./react_dates_overrides.css"
import moment from "moment"
import AppContext, { Context } from "context"
const { useState, useEffect, useContext } = React
export default props => {
    const [show, setShow] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [focusedInput, setFocusedInput] = useState(null)
    const { setFilter } = useContext<Context>(AppContext)

    const setDates = (start, end) => {
        setStartDate(start)
        setEndDate(end)
        if (start) {
            setFilter("startDate", parseInt(start.format("x")))
        }
        if (end) {
            setFilter("endDate", parseInt(end.format("x")))
        }
    }
    const isAfterToday = day => {
        const yesterday = moment()
            // .subtract(1, "day")
            .startOf("day")
        return day.isAfter(yesterday)
    }
    const toggleShow = () => {
        if (show) {
            setShow(false)
        } else {
            setShow(true)
            setFocusedInput("startDate")
        }
    }
    const handleFocusChange = focusInput => {
        if (focusInput !== focusedInput) {
            setFocusedInput(focusInput)
        }
    }
    const getPlaceholder = () => {
        if (startDate || endDate) {
            const start = startDate
                ? moment(startDate).format("MM/DD/YYYY")
                : ""
            const end = endDate ? moment(endDate).format("MM/DD/YYYY") : ""
            return `${start} - ${end}`
        } else {
            return "All Time"
        }
    }
    useEffect(() => {
        if (focusedInput === null && show) {
            if (startDate) {
                setFilter("startDate", parseInt(startDate.format("x")))
            }
            if (endDate) {
                setFilter("endDate", parseInt(endDate.format("x")))
            }
            setShow(false)
        }
    })
    return (
        <div style={styles.container}>
            <div style={styles.iconContainer}>
                <Calendar
                    size={18}
                    style={{
                        fill: DefaultColors.gray[500],
                        cursor: "pointer",
                        marginRight: 8
                    }}
                    onClick={toggleShow}
                />
                {show ? (
                    <DateRangePicker
                        startDate={startDate}
                        startDateId="your_unique_start_date_id"
                        endDate={endDate}
                        endDateId="your_unique_end_date_id"
                        onDatesChange={({ startDate: start, endDate: end }) =>
                            setDates(start, end)
                        }
                        focusedInput={focusedInput}
                        onFocusChange={handleFocusChange}
                        isOutsideRange={isAfterToday}
                        initialVisibleMonth={() =>
                            moment().subtract(1, "month")
                        }
                        hideKeyboardShortcutsPanel
                        showClearDates
                    />
                ) : (
                        <span onClick={toggleShow} style={styles.activeDateRange}>
                            {getPlaceholder()}
                        </span>
                    )}
                <CheveronDown
                    style={{
                        fill: DefaultColors.gray[500],
                        cursor: "pointer",
                        marginLeft: 8
                    }}
                    size={14}
                    onClick={toggleShow}
                />
            </div>
        </div>
    )
}