import * as React from "react"
import styled from "styled-components"
import { Colors, DefaultColors } from "../../util/colors"

const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`

const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: ${props => {
        return props.size ? props.size : `16px`
    }
    };
  height: ${props => props.size ? props.size : `16px`};
  background: ${props => (props.checked ? Colors.red : "transparent")}
  border-radius: 3px;
  transition: all 150ms;
  border: ${props => (props.checked ? `${props.borderWidth || 2}px solid ${Colors.red}` : `${props.borderWidth || 2}px solid ${DefaultColors.gray[400]}`)}

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 0px ${Colors.red};
  }

  ${Icon} {
    visibility: ${props => (props.checked ? "visible" : "hidden")}
  }
`

const Checkbox = ({ className, checked, ...props }) => {
    return (
        <CheckboxContainer className={className}>
            <HiddenCheckbox
                checked={checked}
                {...props}
                onChange={props.onChange}
            />
            <StyledCheckbox checked={checked} {...props}>
                <Icon viewBox="0 0 24 24">
                    <polyline points="20 6 9 17 4 12" />
                </Icon>
            </StyledCheckbox>
        </CheckboxContainer>
    )
}

export default props => {
    return (
        <div>
            <label>
                <Checkbox {...props} />
                {props.label && <span style={props.labelStyle}>{props.label}</span>}
            </label>
        </div>
    )
}
