import { Colors, DefaultColors } from "util/colors"

export default {
    container: {
        width: 350,
        margin: "0 auto",
        display: "flex",
        alignItems: "center" as "center",
        flexDirection: "column" as "column"
    },
    innerContainer: {
        marginTop: 24,
        backgroundColor: DefaultColors.white,
        borderRadius: 4,
        padding: 24,
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "center" as "center",
        alignItems: "center" as "center",
        width: "100%",
        boxShadow: `0px 4px 20px rgba(0,0,0,.05)`
    },
    titleContainer: {
        marginBottom: 24
    },
    title: {
        fontSize: 24,
        color: DefaultColors.gray[800],
        textAlign: "center" as "center",
        fontWeight: 600 as 600
    },
    button: {
        transition: "all .5s"
    },
    submitButton: {},
    forgotPasswordButton: {
        marginRight: 16,
        backgroundColor: DefaultColors.gray[300],
        transition: "all .5s"
    },
    forgotPasswordButtonText: {
        color: DefaultColors.gray[600],
        fontWeight: 600 as 600
    },
    forgotPasswordButtonHover: {
        backgroundColor: DefaultColors.gray[200]
    },
    buttonText: {
        fontWeight: 600 as 600
    },
    buttonHoverStyle: {
        backgroundColor: DefaultColors.green[500]
    },
    ctaContainer: {
        display: "flex",
        borderTopWidth: 1,
        borderTopStyle: "solid" as "solid",
        borderTopColor: Colors.grayBorder,
        justifyContent: "flex-end" as "flex-end",
        width: "100%",
        paddingTop: 16,
        marginTop: 16
    },
    error: {
        backgroundColor: DefaultColors.red[200],
        width: "100%",
        borderRadius: 4,
    },
    errorMessage: {
        color: DefaultColors.red[800],
        fontSize: 14,
        fontWeight: 400 as 400,
        width: "100%"
    },
    errorContent: {
        display: "flex",
        alignItems: "center" as "center",
        padding: 16
    }, success: {
        backgroundColor: DefaultColors.green[200],
        width: "100%",
        borderRadius: 4,
    },
    successMessage: {
        color: DefaultColors.green[800],
        fontSize: 14,
        fontWeight: 400 as 400,
        width: "100%"
    },
    successContent: {
        display: "flex",
        alignItems: "center" as "center",
        padding: 16
    }
}
