import { navigate } from "@reach/router"
import * as React from "react"
import { CreateUserAccountType } from "../../types"
import { withAppContext, Context } from "../../Context"
import SignUpForm from "./components/form"
import Reviews from "./components/quotes"
import styles from "./styles"

type Props = {
    requestLogin: (email: string, password: string) => void
    context: Context
}

type State = {
    account?: CreateUserAccountType
    errors?: { code: string; message: string }[]
    period?: string
    plans?: any[]
}

export class SignUp extends React.Component<Props, State> {
    state = {
        period: null,
        plans: [],
        account: null,
        errors: []
    }
    componentDidMount() {
        this.fetchProducts().then((p) => {
            p.forEach((product) => {
                fetch(
                    `https://us-central1-dragtuner-de058.cloudfunctions.net/stripe-getPrices`,
                    {
                        body: JSON.stringify({ product: product.id }),
                        method: "POST"
                    }
                )
                    .then((res) => {
                        return res.json()
                    })
                    .then((prices) => {
                        const defaultSelected = prices.find(
                            (p) => p?.recurring.interval === "year"
                        )
                        this.setState({
                            plans: prices,
                            period: defaultSelected.id
                        })
                    })
            })
        })
    }
    fetchProducts = () => {
        return fetch(
            `https://us-central1-dragtuner-de058.cloudfunctions.net/stripe-getProducts`
        )
            .then((res) => res.json())
            .catch((err) => console.log(err))
    }
    requestLogin = (email: string, password: string) => {
        const { context } = this.props
        return context.firebaseApp
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((res) => res)
    }
    handleSubmit = (account: CreateUserAccountType) => {
        this.setState({ account, errors: [] }, () => {
            this.props.context.firebaseApp
                .auth()
                .createUserWithEmailAndPassword(account.email, account.password)
                .then((newUser) => {
                    const { user } = newUser
                    this.addUserToDatabase(user)
                    user.sendEmailVerification({ url: "localhost:3000" })
                    user.updateProfile({
                        displayName: account.name,
                        photoURL: null
                    })
                    this.handleSuccessAccountCreation()
                })
                .catch((error) => {
                    if (error) {
                        const { code, message } = error
                        this.setState({ errors: [{ code, message }] })
                    }
                })
        })
    }
    addUserToDatabase = (user: any) => {
        this.props.context.firebaseDB.post(`users/${user.uid}`, {
            data: {
                id: user.uid,
                name: this.state.account.name,
                subscribed: false,
                email: user.email,
                emailVerified: user.emailVerified,
                creationTime: user.metadata.creationTime,
                lastSignInTime: user.metadata.lastSignInTime
            }
        })
    }
    handleSuccessAccountCreation = () => {
        navigate(`/confirm-email/${this.state.account.email}`)
    }
    render() {
        return (
            <div style={styles.screenContainer}>
                <div style={styles.main}>
                    <div style={styles.mainInner}>
                        <a href="https://dragtuner.com">
                            <img
                                src={require("../../images/dt-logo-horizontal.png")}
                                style={{ width: 225, marginBottom: 80 }}
                            />
                        </a>
                        <h1 style={styles.heading}>
                            Start your 7-day free trial
                        </h1>
                        <p style={styles.tagline}>
                            Join thousands of happy DragTuner users and start
                            winning races today.
                        </p>
                        <SignUpForm
                            onSubmit={this.handleSubmit}
                            errors={this.state.errors}
                            plans={this.state.plans}
                        />
                    </div>
                </div>
                <div style={styles.aside}>
                    <div style={styles.asideInner}>
                        <Reviews />
                    </div>
                </div>
            </div>
        )
    }
}

export default withAppContext(SignUp)
