import { DefaultColors } from "util/colors"
export default {
    container: {
        marginTop: 16,
        marginBottom: 0,
        display: "flex"
    },
    title: {
        color: DefaultColors.gray[500],
        fontSize: 13,
        marginRight: 16,
        display: "flex",
        alignItems: "center"
    },
    pillContainer: {
        display: "flex"
    },
    clearFiltersButton: {
        fontSize: 13,
        backgroundColor: "transparent",
        border: `1px solid ${DefaultColors.gray[600]}`,
        padding: "4px 8px",
        transition: "all .5s"
    },
    clearFiltersButtonText: {
        color: DefaultColors.gray[600]
    },
    clearFiltersButtonHover: {
        backgroundColor: DefaultColors.gray[600],
    },
    clearFiltersButtonHoverText: {
        color: DefaultColors.gray[200]
    }
}