export default {
    row: {
        display: "inline-flex",
        width: "100%",
        justifyContent: "space-between" as "space-between"
    },
    halfWidthField: {
        width: "100%",
        borderRadius: 6
    },
    twoColumn: {
        width: "47%",
        borderRadius: 6
    },
    threeColumn: {
        width: "30%",
        borderRadius: 6
    },
    fields: {
        marginTop: 24,
        borderRadius: 6
    },
    fullWidthField: {
        width: "100%",
        borderRadius: 6
    }
}
