import { DefaultColors } from "util/colors"

export default {
    container: {},
    heading: {
        marginBottom: 40,
        color: DefaultColors.red[100],
        fontWeight: 600 as 600,
        fontSize: 24
    },
    quoteContainer: {
        position: "relative" as "relative",
        marginBottom: 40,
        display: "flex",
        flexDirection: "column" as "column",
    },
    quoteText: {
        color: DefaultColors.red[200],
        fontStyle: "italic" as "italic",
        lineHeight: 2.0,
        zIndex: 10
    },
    quoteAuthor: {
        color: DefaultColors.red[100],
        textAlign: "right" as "right",
        marginTop: 12,
        fontSize: 14,
        fontWeight: 600 as 600
    },
    quoteIcon: {
        width: 30,
        position: "absolute" as "absolute",
        top: -8,
        left: -16,
    },
    quoteIconPath: {
        fill: DefaultColors.red[300],
        zIndex: 9,
        opacity: .4
    },
}