import * as React from "react"
import { Close } from "react-zondicons"
import { DefaultColors } from "util/colors"
import styles from "./styles"
type Props = {
    onClick?: () => void
    fill?: string
    text: string
    textColor?: string
}
const Pill: React.StatelessComponent<Props> = (props: Props) => {
    return (
        <div
            style={Object.assign({}, styles.container, {
                backgroundColor: props.fill
            })}
            onClick={props.onClick}
        >
            <span
                style={Object.assign({}, styles.text, {
                    color: props.textColor
                })}
            >
                {props.text}
            </span>
            <Close size={10} style={styles.icon} />
        </div>
    )
}
Pill.defaultProps = {
    fill: DefaultColors.blue[500],
    textColor: DefaultColors.blue[100]
}
export default Pill