import * as React from "react"
import Button from "../button"
import Input from "../forms/input"
import styles from "./styles"
import { animated, Spring } from "react-spring/renderprops"
import { DefaultColors } from "util/colors"
import { MdError, MdCheckCircle } from "react-icons/md"
import { Context, withAppContext } from "context"

type Props = {
    context: Context
}

type State = {
    email?: string
    password?: string
    loading?: boolean
    submitted?: boolean
    error?: { code: string, message: string }
}

class ForgotPasswordForm extends React.Component<Props, State> {
    state = {
        email: "",
        loading: false,
        submitted: false,
        error: null
    }
    handleClick = () => {
        const { email } = this.state
        this.setState({ loading: true })
        this.props.context.firebaseApp
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                this.setState({ loading: false, submitted: true })
            })
            .catch(error => {
                this.setState({ loading: false, error: { message: error.message, code: error.code } })
            })
    }
    render() {
        const { email } = this.state
        return (
            <div style={styles.container}>
                <img src={require("../../images/dt-logo-horizontal.png")} width={250} />
                <div style={styles.innerContainer}>
                    <div style={styles.titleContainer}>
                        <h1 style={styles.title}>Reset Password</h1>
                    </div>
                    <Input
                        testId="email"
                        label="Email"
                        value={email}
                        type="email"
                        onChange={value => this.setState({ email: value })}
                        containerStyle={{ width: "100%" }}
                        style={{ width: "100%" }}
                    />
                    <div style={{ width: "100%" }}>
                        <Spring
                            native
                            force
                            config={{
                                tension: 2000,
                                friction: 100,
                                precision: 1
                            }}
                            from={{ height: this.state.error ? 0 : "auto" }}
                            to={{ height: this.state.error ? "auto" : 0 }}
                        >
                            {(props) => (
                                <animated.div
                                    style={Object.assign(
                                        {},
                                        { overflow: "hidden" },
                                        props
                                    )}
                                >
                                    <div
                                        style={styles.error}
                                        data-testid="error-single"
                                    >
                                        <div style={styles.errorContent}>
                                            <MdError size={20} color={DefaultColors.red[600]} style={{ marginRight: 8 }} />
                                            <p style={styles.errorMessage}>
                                                {this.state.error &&
                                                    this.state.error.message}
                                            </p>
                                        </div>
                                    </div>
                                </animated.div>
                            )}
                        </Spring>
                    </div>
                    <div style={{ width: "100%" }}>
                        <Spring
                            native
                            force
                            config={{
                                tension: 2000,
                                friction: 100,
                                precision: 1
                            }}
                            from={{ height: this.state.submitted ? 0 : "auto" }}
                            to={{ height: this.state.submitted ? "auto" : 0 }}
                        >
                            {(props) => (
                                <animated.div
                                    style={Object.assign(
                                        {},
                                        { overflow: "hidden" },
                                        props
                                    )}
                                >
                                    <div
                                        style={styles.success}
                                        data-testid="success-single"
                                    >
                                        <div style={styles.successContent}>
                                            <MdCheckCircle size={20} color={DefaultColors.green[600]} style={{ marginRight: 8 }} />
                                            <p style={styles.successMessage}>
                                                {this.state.submitted && "Check your email to complete the reset."}
                                            </p>
                                        </div>
                                    </div>
                                </animated.div>
                            )}
                        </Spring>
                    </div>
                    <div style={styles.ctaContainer}>
                        <Button
                            to="/login"
                            label="Go Back"
                            testId="login"
                            style={styles.forgotPasswordButton}
                            textStyle={styles.forgotPasswordButtonText}
                            hoverStyle={styles.forgotPasswordButtonHover}
                        />
                        <Button
                            onClick={this.handleClick}
                            label="Send Reset Link"
                            testId="submit"
                            loading={this.state.loading}
                            textStyle={styles.buttonText}
                            hoverStyle={styles.buttonHoverStyle}
                            style={styles.button}
                            disabled={this.state.submitted}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default withAppContext(ForgotPasswordForm)
