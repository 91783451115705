export type ErrorCode = "auth/user-not-found" | "auth/wrong-password"

export const ErrorCodes = {
    USER_NOT_FOUND: "auth/user-not-found",
    INCORRECT_PASSWORD: "auth/wrong-password"
}

export const ErrorMessages = {
    USER_NOT_FOUND:
        "The user does not exist. Please verify that you entered your email address correctly.",
    INCORRECT_PASSWORD: "The password is incorrect, please try again."
}

export const beautifyErrorMessage = (
    code: ErrorCode | string,
    message: string
) => {
    switch (code) {
        case ErrorCodes.USER_NOT_FOUND:
            return ErrorMessages.USER_NOT_FOUND
        case ErrorCodes.INCORRECT_PASSWORD:
            return ErrorMessages.INCORRECT_PASSWORD
        default:
            console.log("Error logging in: ", code)
            return message
    }
}
