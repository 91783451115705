import { Colors, DefaultColors } from "util/colors"

export default {
    container: {
        height: 80,
        backgroundColor: Colors.black,
        alignItems: "center" as "center",
        display: "flex",
        justifyContent: "flex-start" as "flex-start",
        cursor: "pointer" as "pointer",
        boxShadow: "0px 0px 15px rgba(0,0,0,.25)",
        position: "fixed" as "fixed",
        // position: "sticky" as "sticky",
        width: "100%",
        zIndex: 9999,
        top: 0
    },
    contentContainer: {
        paddingLeft: 24,
        paddingRight: 24,
        alignItems: "center" as "center",
        display: "flex",
        justifyContent: "flex-start" as "flex-start",
        width: "100%"
    },
    userInfo: {
        display: "flex",
        alignItems: "center" as "center",
        position: "relative" as "relative",
        height: 80,
        zIndex: 1000
    },
    vehicleSelect: {
        display: "flex",
        alignItems: "center" as "center",
        position: "relative" as "relative",
        height: 80,
        zIndex: 1000,
        marginRight: 48
    },
    displayName: {
        marginRight: 8,
        color: DefaultColors.gray[300],
        fontSize: 14
    },
    userIcon: {
        marginRight: 8,
        fill: DefaultColors.gray[400]
    },
    vehicleIcon: {
        marginRight: 8,
        fill: DefaultColors.gray[400]
    },
    cheveronIcon: {
        fill: DefaultColors.gray[200]
    },
    menuContainer: {
        backgroundColor: Colors.white,
        position: "absolute" as "absolute",
        right: 0,
        top: 54,
        borderRadius: 4,
        padding: 12,
        boxShadow: "0px 0px 20px rgba(0,0,0,.25)",
        zIndex: 9999,
        minWidth: 140
    },
    menu: {
        listStyleType: "none" as "none",
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
        margin: 0
    },
    menuItem: {
        display: "flex",
        alignItems: "center" as "center",
        marginBottom: 12
    },
    menuItemLast: {
        marginBottom: 0
    },
    menuItemText: {
        marginLeft: 8,
        fontSize: 14,
        color: DefaultColors.gray[900]
    },
    menuItemIcon: {
        fill: DefaultColors.gray[600]
    }
}
