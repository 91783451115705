import * as React from "react"
import Header from "../components/header"
import Sidebar from "../components/sidebar"
import styles from "./styles"

type Props = {
    children: React.ReactElement<any>
}

export default (props: Props) => {
    return (
        <div style={styles.container}>
            <Header />
            <div style={styles.main}>
                <Sidebar />
                <div style={styles.content}>{props.children}</div>
            </div>
        </div>
    )
}
