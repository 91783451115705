import { Redirect } from "@reach/router"
import LoginForm from "components/login-form"
import * as React from "react"
import { withAppContext, Context } from "../../Context"
import styles from "./styles"

type Props = {
    requestLogin: (email: string, password: string) => void
    context: Context
}

class Login extends React.Component<Props, {}> {
    requestLogin = (email: string, password: string) => {
        const { context } = this.props
        return context.firebaseApp
            .auth()
            .signInWithEmailAndPassword(email, password)
    }
    render() {
        const { user, activeSubscription } = this.props.context.state
        if (!user) {
            return (
                <div style={styles.container}>
                    <LoginForm onRequestLogin={this.requestLogin} />
                </div>
            )
        } else if (activeSubscription) {
            return <Redirect from="" to="/" noThrow />
        } else {
            return <Redirect from="" to="/settings" noThrow />
        }
    }
}

export default withAppContext(Login)

export { Login }
