export default {
    container: {
        display: "flex" as "flex",
        flexDirection: "column" as "column",
        marginBottom: 16,
        flex: 1
    },
    inline: {
        flexDirection: "row" as "row",
        alignItems: "center" as "center"
    },
    input: {
        height: 41,
        width: 260,
        borderRadius: 3,
        border: "1px solid #e3e3e3",
        paddingLeft: 8,
        fontSize: 14,
        fontWeight: 400
    },
    label: {
        color: "#888888",
        fontSize: 14,
        width: 100
    }
}
