import { navigate } from "@reach/router"
import * as React from "react"
import { AutoSizer, Column, Table } from "react-virtualized"
import "react-virtualized/styles.css"
import {
    EditPencil,
    Trash,
    ViewShow,
    MinusOutline,
    CheckmarkOutline,
    Close,
    Checkmark
} from "react-zondicons"
import { Context } from "../../Context"
import Checkbox from "../checkbox"
import moment from "moment"
import styles from "./styles"
import { DefaultColors, Colors } from "util/colors"
import Button from "components/button"

type Props = {
    data?: any[]
    context: Context
    onRequestDelete: (id: string) => void
}

type State = {
    selectAll?: boolean
    mode: string
    selected?: string
}

export default class Vehicles extends React.Component<Props, State> {
    state = {
        selectAll: false,
        mode: "view",
        selected: null
    }
    toggleSelectAll = (event) => {
        this.setState({ selectAll: event.target.checked })
        if (event.target.checked) {
            const all = this.props.data.map((d) => d.id)
            this.props.context.selectVehicle(all)
        } else {
            this.props.context.deselectVehicle([])
        }
    }
    getStyleForRow = ({ index }) => {
        if (index === -1) {
            return {}
        } else {
            return {
                borderTop: "1px solid #e3e3e3"
            }
        }
    }
    handleRowSelect = (id: string) => {
        if (this.props.context.state.selectedVehicles.indexOf(id) !== -1) {
            this.props.context.deselectVehicle([id])
        } else {
            this.props.context.selectVehicle([id])
        }
    }
    handleRowClick = ({ rowData }) => {
        const { id } = rowData
        // Navigate to edit vehicle
        navigate(`/vehicles/${id}`)
    }
    isRowChecked = (id: string) => {
        if (this.props.context.state.selectedVehicles.indexOf(id) !== -1) {
            return true
        } else {
            return false
        }
    }
    getActions = ({ rowData }) => {
        return (
            <div>
                <ViewShow
                    size={14}
                    onClick={() => navigate(`/vehicle/${rowData.id}`)}
                    style={{ marginRight: 16, fill: "#999999" }}
                    className="action-view"
                />
                <EditPencil
                    size={14}
                    onClick={() => navigate(`/vehicle/${rowData.id}`)}
                    style={{ marginRight: 16, fill: "#999999" }}
                    className="action-edit"
                />
                <Trash
                    size={14}
                    onClick={() => this.props.onRequestDelete(rowData.id)}
                    style={{ fill: "#999999" }}
                    className="action-delete"
                />
            </div>
        )
    }
    onColumnClick = ({ event, dataKey }) => {
        const noClickColumns = ["selected", "actions"]
        if (noClickColumns.indexOf(dataKey) !== -1) {
            event.stopPropagation()
        }
    }
    toggleEditMode = (id: string) => {
        this.setState({ mode: "edit", selected: id })
    }
    exitEditMode = () => {
        this.setState({ mode: "view", selected: null })
    }
    selectVehicle = (id: string) => {
        this.setState({ selected: id })
    }
    render() {
        if (this.props.data) {
            return (
                <div style={styles.vehicleCardContainer}>
                    {this.props.data.map((v) => {
                        return (
                            <div style={styles.vehicleCard}>
                                <span style={styles.vehicleName}>{v.name}</span>
                                <ul style={styles.fieldList}>
                                    {v.fields.map((f) => {
                                        return (
                                            <li style={styles.fieldItem}>
                                                {this.state.mode === "edit" &&
                                                this.state.selected === v.id ? (
                                                    <button
                                                        style={
                                                            styles.iconButton
                                                        }
                                                    >
                                                        <MinusOutline
                                                            size={16}
                                                            title="Delete"
                                                            style={{
                                                                fill: DefaultColors
                                                                    .red[600]
                                                            }}
                                                        />
                                                    </button>
                                                ) : (
                                                    <CheckmarkOutline
                                                        size={16}
                                                        style={{
                                                            fill: DefaultColors
                                                                .green[600]
                                                        }}
                                                    />
                                                )}
                                                <span
                                                    style={
                                                        styles.fieldItemLabel
                                                    }
                                                >
                                                    {f}
                                                </span>
                                            </li>
                                        )
                                    })}
                                </ul>
                                {this.state.mode === "edit" &&
                                    this.state.selected === v.id && (
                                        <Button
                                            label="Add Field"
                                            onClick={() => {}}
                                        />
                                    )}
                                <div style={styles.actions}>
                                    {this.state.selected !== v.id ? (
                                        <>
                                            <button
                                                style={styles.iconButton}
                                                onClick={() =>
                                                    this.toggleEditMode(v.id)
                                                }
                                            >
                                                <EditPencil
                                                    size={16}
                                                    style={styles.editIcon}
                                                />
                                            </button>
                                            <button style={styles.iconButton}>
                                                <Trash
                                                    size={16}
                                                    style={styles.deleteIcon}
                                                />
                                            </button>
                                        </>
                                    ) : (
                                        <div style={styles.saveContainer}>
                                            <button
                                                style={styles.iconButton}
                                                onClick={this.exitEditMode}
                                            >
                                                <Close
                                                    size={16}
                                                    style={styles.cancelIcon}
                                                />
                                            </button>
                                            <button style={styles.iconButton}>
                                                <Checkmark
                                                    size={16}
                                                    style={styles.saveIcon}
                                                />
                                            </button>
                                            {/* <Button
                                                onClick={this.exitEditMode}
                                                label="Cancel"
                                                style={styles.cancelButton}
                                                textStyle={
                                                    styles.CancelButtonText
                                                }
                                            />
                                            <Button label="Save" /> */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        } else {
            return <div data-testid="empty" />
        }
    }
}
