import * as React from "react"
import Menu from "./components/menu"
import MenuItem from "./components/menu/item"
import styles from "./styles"
const pkg = require("../../../../package.json")
import { Location } from "@reach/router"

type Props = {}

export default (props: Props) => (
    null
    // < Location >
    // { locationProps => (
    //     <div style={styles.container}>
    //         <div style={styles.mainMenu}>
    //             <p style={styles.sectionHeading}>Navigation</p>
    //             <Menu>
    //                 <MenuItem
    //                     to="/"
    //                     text="Runs"
    //                     icon={require("../../../images/stopwatch.svg")}
    //                     active={
    //                         locationProps.location.pathname === "/" ||
    //                         locationProps.location.pathname.includes("runs")
    //                     }
    //                 />
    //                 <MenuItem
    //                     to="/weather"
    //                     text="Weather"
    //                     icon={require("../../../images/day-cloud.svg")}
    //                     active={locationProps.location.pathname.includes(
    //                         "weather"
    //                     )}
    //                 />
    //                 <MenuItem
    //                     to="/vehicles"
    //                     text="Vehicles"
    //                     icon={require("../../../images/car-11.svg")}
    //                     active={locationProps.location.pathname.includes(
    //                         "vehicles"
    //                     )}
    //                 />
    //                 <MenuItem
    //                     to="/et-prediction"
    //                     text="ET Prediction"
    //                     icon={require("../../../images/trending-up.svg")}
    //                     active={locationProps.location.pathname.includes(
    //                         "et-prediction"
    //                     )}
    //                 />
    //             </Menu>
    //         </div>
    //         <div style={styles.settingsMenu}>
    //             <p style={styles.sectionHeading}>Settings</p>
    //             <Menu>
    //                 <MenuItem
    //                     to="/settings"
    //                     text="Settings"
    //                     icon={require("../../../images/cog.svg")}
    //                     active={locationProps.location.pathname.includes(
    //                         "settings"
    //                     )}
    //                 />
    //                 <MenuItem
    //                     to="/logout"
    //                     text="Logout"
    //                     icon={require("../../../images/logout-2.svg")}
    //                 />
    //             </Menu>
    //         </div>
    //         <div style={styles.version}>
    //             <span>Version: {pkg.version}</span>
    //         </div>
    //     </div>
    // )}
    // </Location >
)
