import firebase from "firebase"
import "firebase/auth"
const Rebase = require("re-base")

const config = {
    apiKey: "AIzaSyA65n5l0sVXOW0CDEFiIKGPBA8fum0qRTw",
    authDomain: "dragtuner-de058.firebaseapp.com",
    databaseURL: "https://dragtuner-dev.firebaseio.com/",
    storageBucket: "dragtuner-de058.appspot.com"
}
const firebaseApp = !firebase?.apps?.length
    ? firebase.initializeApp(config)
    : firebase.app() || firebase.app()

const firebaseDB = Rebase.createClass(firebaseApp.database())

export { firebaseApp, firebaseDB }
