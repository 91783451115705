import { Colors, DefaultColors } from "util/colors"

export default {
    sectionContent: {
        // padding: 24
    },
    section: {
        borderBottomWidth: 1,
        borderBottomColor: "#EAEAEA",
        borderBottomStyle: "solid" as "solid"
    },
    sectionHeader: {
        display: "flex",
        alignItems: "center" as "center",
        justifyContent: "flex-start" as "flex-start"
    },
    sectionHeaderNumber: {
        fontSize: 18,
        color: "#D64545",
        borderWidth: 2,
        borderColor: "#D64545",
        borderStyle: "solid" as "solid",
        borderRadius: 100,
        width: 24,
        height: 24,
        alignItems: "center" as "center",
        justifyContent: "center" as "center",
        display: "flex",
        marginRight: 24
    },
    sectionHeading: {
        fontWeight: 600 as 600,
        color: DefaultColors.gray[800]
    },
    submitButton: {
        display: "block",
        justifyContent: "center" as "center",
        width: "100%",
        borderRadius: 4,
        transition: "all .5s"
    },
    submitButtonHover: {
        backgroundColor: DefaultColors.green[500]
    },
    submitButtonText: {
        fontSize: 16,
        fontWeight: 600,
        textShadow: `0px 1px 2px rgba(0,0,0,.15)`
    },
    ctaContainer: {
        marginTop: -12,
        alignItems: "flex-end" as "flex-end",
        justifyContent: "flex-end" as "flex-end",
        paddingBottom: 24,
        paddingTop: 24
    },
    fields: {
        marginTop: 24
    },
    fullWidthField: {
        width: "100%"
    },
    row: {
        display: "inline-flex",
        width: "100%",
        justifyContent: "space-between" as "space-between"
    },
    halfWidthField: {
        width: "100%"
    },
    twoColumn: {
        width: "47%"
    },
    threeColumn: {
        width: "30%"
    },
    createAccountContainer: {
        borderTopColor: Colors.grayBorder,
        width: "100%",
        textAlign: "center" as "center",
        paddingTop: 16
    },
    createAccountText: {
        fontSize: 13,
        color: Colors.grayButtonText,
        fontWeight: 400 as 400
    },
    createAccountLink: {
        color: DefaultColors.gray[800],
        fontWeight: 600 as 600
    },
    termsCheckbox: {
        display: "flex",
        paddingTop: 16,
        paddingBottom: 16
    },
    termsText: {
        marginLeft: 8,
        fontSize: 15,
        color: "#555"
    },
    termsLink: {
        color: Colors.red
    },
    disclaimer: {
        backgroundColor: DefaultColors.gray[200],
        marginBottom: 24,
        borderRadius: 4
    },
    disclaimerText: {
        color: DefaultColors.gray[600],
        display: "inline-block"
        // flexWrap: "wrap" as "wrap",
        // alignItems: "center" as "center"
    },
    disclaimerContent: {
        padding: 16
    },
    errorContainer: {
        backgroundColor: DefaultColors.red[200],
        marginBottom: 24,
        borderRadius: 4
    },
    errorText: {
        color: DefaultColors.red[800],
        display: "flex",
        alignItems: "center" as "center"
    },
    errorContent: {
        padding: 16
    }
}
