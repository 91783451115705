import * as React from "react"
import { navigate } from "@reach/router"
import colors from "../../colors"
import Timeslip from "../../components/run/timeslip"
import Weather from "../../components/run/weather"
import Details from "../../components/run/details"
import { DashboardLayout as Layout } from "../../layouts"
import {
    RunType,
    TimeSlipType,
    ViewMode,
    RunLocation,
    ConditionsType,
    RunDetailsType
} from "../../types"
import { withAppContext, Context } from "../../Context"
import styles from "./styles"
import clonedeep from "lodash.clonedeep"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { capitalizeFirstLetter, generateNewRun } from "util/helpers"
import Button from "components/button"
import { DefaultColors } from "util/colors"
import { Weather as WeatherSDK } from "@bobbyholtzner/dragtuner-sdk"

type Props = {
    context: Context
    id: string
    mode: ViewMode
    location: RunLocation
}
type State = {
    loading?: boolean
    run: RunType
    tempRun: RunType
    mode: ViewMode
    selectedTab?: number
}

class Run extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        let mode: ViewMode = "view"
        if (props.location.state?.mode) {
            mode = props.location.state?.mode
        }
        this.state = {
            run: null,
            tempRun: null,
            mode,
            selectedTab: 0
        }
    }
    componentDidMount() {
        const { id, context } = this.props
        if (id === "new") {
            const run = generateNewRun()
            this.setState({ run, tempRun: run, mode: "create" })
            this.addCurrentConditionsToRun()
        }
        if (context.state.runs && id) {
            const run = context.state.runs.find((r) => r.id === id)
            if (run) {
                this.setState({ run, tempRun: clonedeep(run) })
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { id, context } = this.props
        if (!prevState.run && context.state.runs && id) {
            const run = context.state.runs.find((r) => r.id === id)
            if (run) {
                this.setState({ run, tempRun: clonedeep(run) })
            }
        }
    }
    addCurrentConditionsToRun = () => {
        this.requestCurrentLocation()
    }
    requestCurrentLocation = () => {
        this.setState({ loading: true })
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude, altitude, altitudeAccuracy } =
                    position.coords
                let elevation = altitudeAccuracy !== -1 ? altitude : null
                this.handleLocationReturn({
                    latitude,
                    longitude,
                    elevation
                })
            },
            (error) => {
                this.handleLocationReturn(null)
                setTimeout(() => {
                    alert(
                        "Error fetching your location. Please try again later."
                    )
                }, 150)
            },
            { enableHighAccuracy: true, timeout: 20000 }
        )
    }
    handleLocationReturn = async (location: {
        latitude: number
        longitude: number
        elevation: number
    }) => {
        let weatherProviderSetting =
            this.props.context.state.userInfo.settings.weatherProvider
        if (weatherProviderSetting === WeatherSDK.WeatherProviders.DARKSKY) {
            weatherProviderSetting = WeatherSDK.WeatherProviders.ACCUWEATHER
        }
        const weather = await WeatherSDK.GetWeather(
            location,
            (weatherProviderSetting as WeatherSDK.WeatherProviderType) ||
                WeatherSDK.WeatherProviders.ACCUWEATHER
        )
        const { tempRun } = this.state
        tempRun.conditions.weather = weather
        localStorage.setItem("latestWeather", JSON.stringify(weather))
        this.setState({ tempRun, loading: false })
    }
    addRun = () => {
        let generatedKey = ""
        let userId = this.props.context.state.user.uid
        let { conditions, details, timeslip, vehicle } = this.state.tempRun
        vehicle = this.props.context.state.selectedVehicle.id
        // timeslip.event = this.props.context.state.selectedEvent
        // timeslip.track = this.props.context.state.selectedTrack
        if (vehicle === "") {
            setTimeout(() => {
                console.error("Select a vehicle")
                // Alert.alert(
                //     "Cannot save run",
                //     "You must select a vehicle before saving the run."
                // )
            }, 150)
            return
        }
        this.props.context.firebaseDB
            .push(`runs/${userId}`, {
                data: {
                    timeslip,
                    conditions,
                    details,
                    userId,
                    vehicle,
                    includeInETPrediction: true,
                    createdAt: new Date().getTime(),
                    updatedAt: new Date().getTime()
                }
            })
            .then((data) => {
                generatedKey = data.key
                console.log(generatedKey)
                this.props.context.firebaseDB
                    .update(`runs/${userId}/${generatedKey}`, {
                        data: {
                            timeslip,
                            conditions,
                            details,
                            userId,
                            vehicle,
                            includeInETPrediction: true,
                            createdAt: new Date().getTime(),
                            updatedAt: new Date().getTime(),
                            id: generatedKey
                        }
                    })
                    .then(() => {
                        navigate("/")
                        // this.handleGoBack()
                    })
                    .catch((err) => {
                        setTimeout(() => {
                            // Alert.alert("Error saving run", err.message)
                        }, 150)
                    })
            })
            .catch((err) => {
                setTimeout(() => {
                    // Alert.alert("Error saving run", err.message)
                }, 150)
            })
    }
    updateTimeslip = (timeslip: TimeSlipType) => {
        const { tempRun } = this.state
        tempRun.timeslip = timeslip
        this.setState({ tempRun })
    }
    updateConditions = (conditions: ConditionsType) => {
        const { tempRun } = this.state
        console.log(conditions)
        tempRun.conditions = conditions
        this.setState({ tempRun })
    }
    updateDetails = (details: RunDetailsType) => {
        const { tempRun } = this.state
        tempRun.details = details
        this.setState({ tempRun })
    }
    toggleMode = (mode: ViewMode) => {
        this.setState({ mode })
    }
    save = () => {
        this.setState({ run: this.state.tempRun })
        const { uid } = this.props.context.state.user
        this.props.context.firebaseDB
            .update(`runs/${uid}/${this.props.id}`, {
                data: this.state.tempRun
            })
            .then(() => {
                console.log("Successfully Edited the run")
                this.toggleMode("view")
            })
            .catch((err) => {
                console.log("There was an error:  ", err)
            })
    }
    onSelectTab = (index: number) => {
        this.setState({ selectedTab: index })
    }
    render() {
        return (
            <Layout>
                {this.state.run ? (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            overflow: "scroll"
                        }}
                    >
                        <div style={styles.pageHeader}>
                            <div style={styles.headingContainer}>
                                <h1 style={styles.heading}>
                                    {capitalizeFirstLetter(this.state.mode)} Run
                                </h1>
                                <p style={styles.description}>
                                    View or edit the timeslip, conditions and
                                    notes.
                                </p>
                            </div>
                            <div style={styles.actionsContainer}>
                                {this.state.mode === "view" && (
                                    <Button
                                        label="Edit"
                                        textStyle={{
                                            fontWeight: 600,
                                            textShadow: `0px 1px 3px ${DefaultColors.blue[400]}`
                                        }}
                                        hoverStyle={{
                                            // backgroundColor: DefaultColors.blue[400],
                                            boxShadow:
                                                "0 7px 14px rgba(50,50,93,.1),0 3px 6px rgba(0,0,0,.08)",
                                            transform: "translateY(-1px)"
                                        }}
                                        style={{
                                            transition: "all .15s ease",
                                            backgroundColor:
                                                DefaultColors.blue[500],
                                            marginRight: 12,
                                            boxShadow:
                                                "0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)"
                                        }}
                                        onClick={() =>
                                            this.toggleMode("edit" as ViewMode)
                                        }
                                    />
                                )}
                                {this.state.mode === "edit" && (
                                    <div style={styles.buttonContainer}>
                                        <Button
                                            label="Cancel"
                                            textStyle={{
                                                fontWeight: 600,
                                                textShadow: `0px 1px 3px ${DefaultColors.gray[400]}`
                                            }}
                                            hoverStyle={{
                                                // backgroundColor: DefaultColors.blue[400],
                                                boxShadow:
                                                    "0 7px 14px rgba(50,50,93,.1),0 3px 6px rgba(0,0,0,.08)",
                                                transform: "translateY(-1px)"
                                            }}
                                            style={{
                                                transition: "all .15s ease",
                                                backgroundColor:
                                                    DefaultColors.gray[400],
                                                marginRight: 12,
                                                boxShadow:
                                                    "0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)"
                                            }}
                                            onClick={() =>
                                                this.toggleMode(
                                                    "view" as ViewMode
                                                )
                                            }
                                        />
                                        <Button
                                            label="Save"
                                            textStyle={{
                                                fontWeight: 600,
                                                textShadow: `0px 1px 3px ${DefaultColors.green[400]}`
                                            }}
                                            hoverStyle={{
                                                // backgroundColor: DefaultColors.blue[400],
                                                boxShadow:
                                                    "0 7px 14px rgba(50,50,93,.1),0 3px 6px rgba(0,0,0,.08)",
                                                transform: "translateY(-1px)"
                                            }}
                                            style={{
                                                transition: "all .15s ease",
                                                backgroundColor:
                                                    DefaultColors.green[500],
                                                marginRight: 12,
                                                boxShadow:
                                                    "0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)"
                                            }}
                                            onClick={this.save}
                                        />
                                    </div>
                                )}
                                {this.state.mode === "create" && (
                                    <Button
                                        label="Save"
                                        textStyle={{
                                            fontWeight: 600,
                                            textShadow: `0px 1px 3px ${DefaultColors.green[500]}`
                                        }}
                                        hoverStyle={{
                                            // backgroundColor: DefaultColors.blue[400],
                                            boxShadow:
                                                "0 7px 14px rgba(50,50,93,.1),0 3px 6px rgba(0,0,0,.08)",
                                            transform: "translateY(-1px)"
                                        }}
                                        style={{
                                            transition: "all .15s ease",
                                            backgroundColor:
                                                DefaultColors.green[500],
                                            marginRight: 12,
                                            boxShadow:
                                                "0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)"
                                        }}
                                        onClick={() => this.addRun()}
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            style={{
                                padding: 24,
                                backgroundColor: colors.gray[100],
                                width: "50%",
                                border: `1px solid ${colors.gray[300]}`,
                                borderRadius: 4,
                                boxShadow: "0px 4px 20px rgba(0,0,0,.05)",
                                position: "relative"
                                // backgroundColor: "rgba(0,0,0,.12)"
                            }}
                        >
                            <Tabs
                                onSelect={this.onSelectTab}
                                selectedIndex={this.state.selectedTab}
                            >
                                <TabList style={styles.tablist}>
                                    <Tab
                                        style={Object.assign(
                                            {},
                                            styles.tab,
                                            this.state.selectedTab === 0 &&
                                                styles.activeTab
                                        )}
                                    >
                                        Timeslip
                                    </Tab>
                                    <Tab
                                        style={Object.assign(
                                            {},
                                            styles.tab,
                                            this.state.selectedTab === 1 &&
                                                styles.activeTab
                                        )}
                                    >
                                        Weather
                                    </Tab>
                                    <Tab
                                        style={Object.assign(
                                            {},
                                            styles.tab,
                                            this.state.selectedTab === 2 &&
                                                styles.activeTab
                                        )}
                                    >
                                        Details
                                    </Tab>
                                </TabList>
                                <TabPanel>
                                    <Timeslip
                                        timeslip={this.state.tempRun.timeslip}
                                        events={this.props.context.state.events}
                                        tracks={this.props.context.state.tracks}
                                        updateTimeslip={this.updateTimeslip}
                                        mode={this.state.mode}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <Weather
                                        conditions={
                                            this.state.tempRun.conditions
                                        }
                                        mode={this.state.mode}
                                        updateConditions={this.updateConditions}
                                        loading={this.state.loading}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <Details
                                        details={this.state.tempRun.details}
                                        mode={this.state.mode}
                                        updateDetails={this.updateDetails}
                                    />
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                ) : (
                    <div style={styles.noContent}>
                        <span>Run Not Available</span>
                        <span style={styles.noContentDescription}>
                            Are you sure it exists?
                        </span>
                        <Button
                            onClick={() => {}}
                            style={{ marginTop: 12 }}
                            label="Add Run"
                            loading={this.state.loading}
                        />
                    </div>
                )}
            </Layout>
        )
    }
}

export default withAppContext(Run)
export { Run }
