import * as React from "react"
import { ViewMode, ConditionsType, SingleCondition } from "../../../types"
import Input from "../../forms/input"
import styles from "./styles"
import {
    getLabelForField,
    getUnitForField,
    weatherKeysToRender
} from "util/helpers"
import Loader from "react-loading"
import { DefaultColors } from "util/colors"

type Props = {
    conditions: ConditionsType
    updateConditions: (conditions: ConditionsType) => void
    mode: ViewMode
    loading: boolean
}

const Conditions = (props: Props) => {
    const { conditions } = props
    const handleChange = (
        field: string,
        value: string,
        weather: boolean,
        nestedKey?: string
    ) => {
        if (weather) {
            if (nestedKey && nestedKey !== "") {
                conditions.weather[nestedKey][field] = value
            } else {
                conditions.weather[field] = value
            }
        } else {
            conditions[field] = value
        }
        props.updateConditions(conditions)
    }
    const keysToRender = weatherKeysToRender()
    if (conditions) {
        return (
            <div style={styles.container}>
                {conditions?.weather &&
                    keysToRender.map((key) => {
                        const weatherCondition = conditions?.weather[key]
                        const unit = getUnitForField(key as SingleCondition)
                        // if (weatherCondition) {
                        if (key !== "wind") {
                            const label = getLabelForField(key)
                            return (
                                <Input
                                    value={conditions?.weather[key]?.toString()}
                                    label={label}
                                    type="text"
                                    onChange={(value) =>
                                        handleChange(key, value, true)
                                    }
                                    style={{ width: "100%" }}
                                    disabled={
                                        props.mode === "view" || props.loading
                                    }
                                    key={key}
                                    units={unit}
                                />
                            )
                        } else {
                            return Object.keys(conditions?.weather[key]).map(
                                (windKey) => {
                                    const label = getLabelForField(
                                        `${key}${windKey}`
                                    )
                                    return (
                                        <Input
                                            value={conditions?.weather[key][
                                                windKey
                                            ]?.toString()}
                                            label={label}
                                            type="text"
                                            onChange={(value) =>
                                                handleChange(
                                                    windKey,
                                                    value,
                                                    true,
                                                    key
                                                )
                                            }
                                            style={{ width: "100%" }}
                                            disabled={
                                                props.mode === "view" ||
                                                props.loading
                                            }
                                            key={windKey}
                                            units={unit}
                                        />
                                    )
                                }
                            )
                        }
                        // }
                    })}
                <Input
                    value={conditions?.trackTemp}
                    label="Track Temperature"
                    type="text"
                    onChange={(value) =>
                        handleChange("trackTemp", value, false)
                    }
                    style={{ width: "100%" }}
                    disabled={props.mode === "view"}
                />
                <Input
                    value={conditions?.notes}
                    label="Notes"
                    type="text"
                    onChange={(value) => handleChange("notes", value, false)}
                    style={{ width: "100%" }}
                    disabled={props.mode === "view"}
                />
                {props.loading && (
                    <div style={styles.loadingContainer}>
                        <Loader
                            type="bubbles"
                            height={48}
                            width={48}
                            color={DefaultColors.gray[600]}
                            data-testid="loading"
                        />
                    </div>
                )}
            </div>
        )
    } else {
        console.log("Need loader, TODO")
        return null
    }
    props.loading && <div>LOADING MFER</div>
}

export default Conditions
