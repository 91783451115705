import { Link } from "@reach/router"
import * as React from "react"
import { animated, Spring } from "react-spring/renderprops"
import { DefaultColors } from "util/colors"
import { withAppContext, Context } from "../../Context"
import Button from "../button"
import Input from "../forms/input"
import { beautifyErrorMessage } from "./helper"
import styles from "./styles"
import { MdError } from "react-icons/md"

type Props = {
    onRequestLogin: (email: string, password: string) => Promise<void>
    context: Context
}

type State = {
    email?: string
    password?: string
    isResolving?: boolean
    error: {
        title?: string
        message?: string
    }
}

class LoginForm extends React.Component<Props, State> {
    state = {
        email: "",
        password: "",
        isResolving: false,
        error: null
    }
    componentDidMount() {
        window.addEventListener("keypress", (event) => {
            switch (event.keyCode) {
                case 13:
                    this.handleSubmitViaKeyboard()
                    break
                default:
                // Do nothing
            }
        })
        const storedEmail = localStorage.getItem("userEmail")
        if (storedEmail && this.state.email === "") {
            this.setState({ email: storedEmail })
        }
    }
    handleSubmitViaKeyboard = () => {
        const { email, password } = this.state
        if (email !== "" && password !== "") {
            this.handleClick()
        }
    }
    handleClick = () => {
        const { email, password } = this.state
        this.setState({ isResolving: true })
        this.props
            .onRequestLogin(email, password)
            .then((res) => {
                localStorage.setItem("userEmail", email)
                this.setState({ isResolving: false })
            })
            .catch(({ code, message }) => {
                const beautifiedMessage = beautifyErrorMessage(code, message)
                this.setState({
                    error: { message: beautifiedMessage },
                    isResolving: false
                })
            })
    }
    isSubmitDisabled = () => {
        const { email, password } = this.state
        return email === "" || password === ""
    }
    handleFocusPassword = () => {
        const { error } = this.state
        if (error) {
            this.setState({ error: null, password: "" })
        }
    }
    render() {
        const { email, password } = this.state
        return (
            <div style={styles.container}>
                <img src={require("../../images/dt-logo-horizontal.png")} width={225} />
                <div style={styles.innerContainer}>
                    <div style={styles.titleContainer}>
                        <h1 style={styles.title}>Sign In</h1>
                    </div>
                    <Input
                        testId="email"
                        label="Email"
                        value={email}
                        type="email"
                        onChange={(value) => this.setState({ email: value })}
                        style={{ width: "100%" }}
                        containerStyle={{ width: "100%" }}
                    />
                    <Input
                        testId="password"
                        label="Password"
                        value={password}
                        type="password"
                        onChange={(value) => this.setState({ password: value })}
                        onFocus={this.handleFocusPassword}
                        style={{ width: "100%" }}
                        containerStyle={{ width: "100%" }}
                    />
                    <div data-testid="error-container" style={{ width: "100%" }}>
                        <Spring
                            native
                            force
                            config={{
                                tension: 2000,
                                friction: 100,
                                precision: 1
                            }}
                            from={{ height: this.state.error ? 0 : "auto" }}
                            to={{ height: this.state.error ? "auto" : 0 }}
                        >
                            {(props) => (
                                <animated.div
                                    style={Object.assign(
                                        {},
                                        { overflow: "hidden" },
                                        props
                                    )}
                                >
                                    <div
                                        style={styles.error}
                                        data-testid="error-single"
                                    >
                                        <div style={styles.errorContent}>
                                            <MdError size={20} color={DefaultColors.red[600]} style={{ marginRight: 8 }} />
                                            <p style={styles.errorMessage}>
                                                {this.state.error &&
                                                    this.state.error.message}
                                            </p>
                                        </div>
                                    </div>
                                </animated.div>
                            )}
                        </Spring>
                    </div>
                    <div style={styles.ctaContainer}>
                        <Button
                            to="/forgot-password"
                            label="Forgot Password"
                            testId="forgot-password"
                            style={styles.forgotPasswordButton}
                            textStyle={styles.forgotPasswordButtonText}
                            hoverStyle={{
                                backgroundColor: DefaultColors.gray[200]
                            }}

                        />
                        <Button
                            onClick={this.handleClick}
                            label="Sign In"
                            testId="submit"
                            loading={this.state.isResolving}
                            disabled={this.isSubmitDisabled()}
                            hoverStyle={{
                                backgroundColor: DefaultColors.green[500]
                            }}
                            textStyle={styles.buttonText}
                        />
                    </div>
                    <div style={styles.createAccountContainer}>
                        <span style={styles.createAccountText}>
                            Don't have an account?{" "}
                            <Link
                                style={styles.createAccountLink}
                                to="/sign-up"
                            >
                                Create one now
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default withAppContext(LoginForm)
