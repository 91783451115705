import { DefaultColors } from "util/colors"

export default {
    heading: {
        fontSize: 28
    },
    noWeather: {
        position: "absolute" as "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center" as "center",
        justifyContent: "center" as "center",
        fontSize: 24,
        color: "#bdbdbd",
        flexDirection: "column" as "column"
    },
    weatherContainer: {
        display: "flex",
        flexDirection: "column" as "column",
        width: "50%"
    },
    refreshButton: {
        cursor: "pointer",
        color: "#ededed"
    },
    weatherHeader: {
        display: "flex",
        flexDirection: "row" as "row",
        alignItems: "center" as "center",
        justifyContent: "space-between" as "space-between",
        paddingBottom: 24
    },
    weatherHeaderText: {
        display: "flex",
        flexDirection: "column" as "column"
    },
    lastUpdated: {
        fontSize: 12,
        color: DefaultColors.gray[600],
        display: "block",
        marginTop: 4,
        fontStyle: "italic"
    }
}
