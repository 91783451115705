import { DefaultColors, Colors } from "util/colors"

export default {
    container: {
        height: "100%",
        width: "100%"
    },
    row: {
        display: "flex",
        alignItems: "center" as "center"
    },
    title: {
        width: 200
    },
    name: {
        fontSize: 11,
        color: "#212121",
        marginBottom: 0
    },
    track: {
        fontSize: 10,
        color: "#777777",
        marginTop: 0
    },
    header: {
        // backgroundColor: "#ededed",
        paddingTop: 4,
        paddingBottom: 4
        // paddingLeft: 12
        // display: "flex",
        // flexDirection: "row" as "row"
    },
    cell: {
        fontSize: 14
    },
    cellValue: {
        fontSize: 10
    },
    body: {
        paddingLeft: 12,
        height: 400
    },
    vehicleCard: {
        padding: 24,
        boxShadow: "0px 0px 24px rgba(0,0,0, 0.12)",
        borderRadius: 8,
        position: "relative" as "relative",
        marginBottom: 24,
        flex: 1,
        marginRight: 24,
        minWidth: 300
    },
    vehicleCardContainer: {
        display: "flex",
        flexWrap: "wrap" as "wrap"
    },
    vehicleName: {
        fontSize: 24,
        fontWeight: 700 as 700,
        color: DefaultColors.gray[800]
    },
    vehicleDate: {
        fontSize: 12,
        color: DefaultColors.gray[600]
    },
    fieldList: {
        paddingLeft: 0
    },
    fieldItem: {
        display: "flex",
        alignItems: "center",
        paddingTop: 12,
        paddingBottom: 12,
        fontSize: 14,
        color: DefaultColors.gray[800]
    },
    timestampList: {
        display: "flex",
        flexDirection: "column" as "column",
        marginTop: 8
    },
    iconButton: {
        boxShadow: "none",
        border: "none",
        backgroundColor: "transparent",
        cursor: "pointer",
        padding: 0
    },
    actions: {
        position: "absolute" as "absolute",
        top: 24,
        right: 24
    },
    editIcon: {
        fill: DefaultColors.gray[700],
        marginRight: 12
    },
    deleteIcon: {
        fill: DefaultColors.gray[700]
    },
    fieldItemLabel: {
        marginLeft: 12
    },
    saveContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    cancelButton: {
        marginRight: 16,
        backgroundColor: Colors.grayButton
    },
    CancelButtonText: {
        color: Colors.grayButtonText
    },
    saveIcon: {
        fill: Colors.green,
        paddingLeft: 12
    },
    cancelIcon: {
        fill: DefaultColors.gray[600]
    }
}
