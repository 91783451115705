import { DefaultColors } from "util/colors"
import colors from "../../colors"

export default {
    pageHeader: {
        marginBottom: 24,
        display: "flex",
        flexDirection: "row" as "row",
        justifyContent: "space-between"
    },
    heading: {
        marginBottom: 8
    },
    description: {
        fontSize: 12,
        color: colors.gray[600]
    },
    sectionHeading: {
        paddingBottom: 24
    },
    headingContainer: {
        display: "flex",
        flexDirection: "column" as "column"
    },
    buttonContainer: {
        display: "flex"
    },
    actionsContainer: {},
    tablist: {
        display: "flex",
        listStyleType: "none",
        padding: 0,
        marginBottom: 48,
        flex: 1
    },
    tab: {
        fontWeight: 600 as 600,
        color: DefaultColors.gray[500],
        marginRight: 18,
        cursor: "pointer",
        borderWidth: 2,
        borderStyle: "solid",
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 3
    },
    activeTab: {
        borderColor: DefaultColors.red[600],
        color: DefaultColors.white,
        backgroundColor: DefaultColors.red[600]
        // borderBottomWidth: 4,
        // borderBottomStyle: "solid",
        // borderBottomColor: DefaultColors.red[500],
        // paddingBottom: 6
    },
    noContent: {
        position: "absolute" as "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center" as "center",
        justifyContent: "center" as "center",
        fontSize: 24,
        color: DefaultColors.gray[700],
        flexDirection: "column" as "column"
    },
    noContentDescription: {
        fontSize: 12,
        marginTop: 8,
        color: DefaultColors.gray[600]
    }
}
