export default {
    container: {
        // position: "relative" as "relative"
    },
    group: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1
    },
    loadingContainer: {
        position: "absolute" as "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}
