import * as React from "react"
import { TimeSlipType, ViewMode } from "../../../types"
import CreatableSelect from "../../forms/createable-select"
import Select from "../../forms/select"
import Input from "../../forms/input"
import styles from "./styles"

type Props = {
    vehicle?: string
    timeslip: TimeSlipType
    tracks: any[]
    events: any[]
    updateTimeslip: (timeslip: TimeSlipType) => void
    mode: ViewMode
}

const Timeslip = (props: Props) => {
    const { timeslip, events, tracks } = props
    const handleChange = (field: string, value: string) => {
        if (field === "date") {
            const date = new Date(value)
            const timestamp = date.getTime()
            timeslip[field] = timestamp
        } else {
            timeslip[field] = value
        }
        props.updateTimeslip(timeslip)
    }
    const handleInputChange = () => {}
    const eventOptions = events && events.map((e) => ({ label: e, value: e }))
    const trackOptions =
        tracks && tracks.map((t) => ({ label: t.name, value: t.name }))
    const formatDisplayDate = (seconds: number) => {
        const date = new Date(seconds)
        const year = date.getFullYear()
        const month = ("0" + (date.getMonth() + 1)).slice(-2)
        const day = ("0" + date.getDate()).slice(-2)
        const hours = date.getHours()
        const minutes = date.getMinutes()
        const formatted = `${year}-${month}-${day}T${hours}:${minutes}`
        return formatted
    }
    if (timeslip && eventOptions && trackOptions) {
        return (
            <div style={styles.container}>
                <div style={styles.group}>
                    <CreatableSelect
                        name="event"
                        isClearable
                        onChange={(value) => handleChange("event", value)}
                        onInputChange={handleInputChange}
                        options={eventOptions}
                        defaultValue={
                            eventOptions &&
                            eventOptions.find((o) => o.value === timeslip.event)
                        }
                        label="Event"
                        disabled={props.mode === "view"}
                    />
                    <CreatableSelect
                        name="track"
                        isClearable
                        onChange={(value) => handleChange("track", value)}
                        onInputChange={handleInputChange}
                        options={trackOptions}
                        defaultValue={
                            trackOptions &&
                            trackOptions.find((o) => o.value === timeslip.track)
                        }
                        label="Track"
                        style={{ marginLeft: 24 }}
                        disabled={props.mode === "view"}
                    />
                </div>
                <div style={styles.group}>
                    <Input
                        value={formatDisplayDate(timeslip.date)}
                        label="Date"
                        type="datetime-local"
                        onChange={(value) => handleChange("date", value)}
                        style={{ width: "100%" }}
                        disabled={props.mode === "view"}
                    />
                    <Input
                        value={timeslip.session}
                        label="Session"
                        type="text"
                        onChange={(value) => handleChange("session", value)}
                        style={{ width: "100%" }}
                        containerStyle={{ marginLeft: 24 }}
                        disabled={props.mode === "view"}
                    />
                    <Select
                        name="lane"
                        onInputChange={null}
                        style={{ marginLeft: 24, width: "100%" }}
                        options={[
                            { label: "L", value: "L" },
                            { label: "R", value: "R" }
                        ]}
                        onChange={(value) => handleChange("lane", value)}
                        placeholder="Select Lane..."
                        defaultValue={{ value: "L", label: "L" }}
                        isClearable={false}
                        label="Lane"
                        disabled={props.mode === "view"}
                    />
                </div>
                <Input
                    value={timeslip.reactionTime}
                    label="R/T"
                    type="text"
                    onChange={(value) => handleChange("reactionTime", value)}
                    style={{ width: "100%" }}
                    disabled={props.mode === "view"}
                />
                <Input
                    value={timeslip.sixty}
                    label="60'"
                    type="text"
                    onChange={(value) => handleChange("sixty", value)}
                    style={{ width: "100%" }}
                    disabled={props.mode === "view"}
                />
                <Input
                    value={timeslip.threeThirty}
                    label="330'"
                    type="text"
                    onChange={(value) => handleChange("threeThirty", value)}
                    style={{ width: "100%" }}
                    disabled={props.mode === "view"}
                />
                <div style={styles.group}>
                    <Input
                        value={timeslip.sixSixty}
                        label="1/8"
                        type="text"
                        onChange={(value) => handleChange("sixSixty", value)}
                        style={{ width: "100%" }}
                        disabled={props.mode === "view"}
                    />
                    <Input
                        value={timeslip.sixSixtyMph}
                        label="1/8 MPH"
                        type="text"
                        onChange={(value) => handleChange("sixSixtyMph", value)}
                        style={{ width: "100%" }}
                        containerStyle={{ marginLeft: 24 }}
                        disabled={props.mode === "view"}
                    />
                </div>
                <Input
                    value={timeslip.thousand}
                    label="1000'"
                    type="text"
                    onChange={(value) => handleChange("thousand", value)}
                    style={{ width: "100%" }}
                    disabled={props.mode === "view"}
                />
                <div style={styles.group}>
                    <Input
                        value={timeslip.thirteenTwenty}
                        label="1/4"
                        type="text"
                        onChange={(value) =>
                            handleChange("thirteenTwenty", value)
                        }
                        style={{ width: "100%" }}
                        disabled={props.mode === "view"}
                    />
                    <Input
                        value={timeslip.thirteenTwentyMph}
                        label="1/4 MPH"
                        type="text"
                        onChange={(value) =>
                            handleChange("thirteenTwentyMph", value)
                        }
                        style={{ width: "100%" }}
                        containerStyle={{ marginLeft: 24 }}
                        disabled={props.mode === "view"}
                    />
                </div>
            </div>
        )
    } else {
        console.log("Need loader, TODO")
        return null
    }
}

export default Timeslip
