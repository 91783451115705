import { Colors, DefaultColors } from "util/colors"

export default {
    container: {
        backgroundColor: DefaultColors.gray[100],
        flex: 1,
        alignItems: "center" as "center",
        display: "flex"
    }
}
