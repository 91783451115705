import * as React from "react"
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md"
import styles from "./styles"

type Props = {
    selected?: boolean
    plan: any
    onSelect: (field: string, value: string) => void
}

export default (props: Props) => {
    return (
        <div
            style={Object.assign(
                {},
                styles.container,
                props.selected && styles.selectedContainer
            )}
            onClick={() => props.onSelect("plan", props.plan.id)}
        >
            <div style={styles.left}>
                {props.selected ? (
                    <MdRadioButtonChecked />
                ) : (
                    <MdRadioButtonUnchecked />
                )}
                <span style={styles.label}>{props.plan.metadata.label}</span>
            </div>
            <div style={styles.right}>{props.plan.metadata.price}</div>
        </div>
    )
}
