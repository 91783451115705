import Colors from "../../colors"

export default {
    popup: {
        position: "fixed" as "fixed",
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: "auto",
        backgroundColor: "rgba(0,0,0,.5)",
        zIndex: 995
    },
    inner: {
        position: "absolute" as "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
        borderRadius: "4px",
        background: "white",
        width: 400,
        height: 150,
        zIndex: 999,
        padding: 24,
        display: "flex",
        flexDirection: "column" as "column"
    },
    title: {
        fontSize: 20,
        color: Colors.gray[900],
        marginBottom: 16
    },
    subTitle: {
        fontSize: 16,
        color: Colors.gray[700]
    },
    actions: {
        flex: 1,
        alignItems: "flex-end",
        display: "flex",
        justifyContent: "flex-end"
    }
}
