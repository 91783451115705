import { DefaultColors } from "util/colors"
export default {
    container: {
        paddingTop: 0,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 0,
        borderRadius: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 12,
        cursor: "pointer",
        height: 24,
        alignSelf: "center" as "center"
    },
    text: {
        fontSize: 13,
        color: DefaultColors.blue[100],
        marginRight: 8,
        display: "flex",
        alignItems: "center"
    },
    icon: {
        fill: DefaultColors.blue[100],
        position: "relative" as "relative",
        top: -1
    }
}