import { Link } from "@reach/router"
import Button from "components/button"
import Checkbox from "components/checkbox"
import * as React from "react"
import { BsFillInfoCircleFill } from "react-icons/bs"
import { MdError } from "react-icons/md"
import { DefaultColors } from "util/colors"
import { CreateUserAccountType } from "../../../../types"
import styles from "./styles"
import UserAccount from "./user"

type Props = {
    onSubmit: (account: CreateUserAccountType) => void
    plans?: any[]
    errors?: { code: string; message: string }[]
}
type State = {
    account?: CreateUserAccountType
    termsAgree?: boolean
}

export class SignUpForm extends React.Component<Props, State> {
    state = {
        account: {
            email: "",
            password: "",
            passwordConfirm: "",
            name: "",
            plan: null
        },
        termsAgree: false
    }
    handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const { account } = this.state
        // TODO: Make sure the password matches the passwordConfirm
        this.props.onSubmit(account)
    }
    updateUserField = (field: string, value: string) => {
        const account = { ...this.state.account }
        account[field] = value
        this.setState({ account })
    }
    submitButtonEnabled = () => {
        const { account, termsAgree } = this.state
        const { email, password, passwordConfirm, name } = account
        if (
            email !== "" &&
            password !== "" &&
            passwordConfirm !== "" &&
            name !== "" &&
            termsAgree
        ) {
            return true
        }
        return false
    }
    handleChangeTerms = (e) => {
        this.setState({ termsAgree: e.target.checked })
    }
    render() {
        return (
            <form onSubmit={this.handleSubmit} data-testid="signup-form">
                <div style={styles.section}>
                    <div style={styles.sectionContent}>
                        <UserAccount
                            {...this.state.account}
                            onChangeField={this.updateUserField}
                            plans={this.props.plans}
                        />
                        <div style={styles.termsCheckbox}>
                            <Checkbox
                                size={14}
                                borderWidth={1}
                                checked={this.state.termsAgree}
                                onChange={this.handleChangeTerms}
                            />
                            <span style={styles.termsText}>
                                I accept DragTuner's{" "}
                                <a
                                    style={styles.termsLink}
                                    target="_blank"
                                    href="https://dragtuner.com/terms-conditions.html"
                                >
                                    Terms of Service
                                </a>
                            </span>
                        </div>
                    </div>
                    <div style={styles.ctaContainer}>
                        <Button
                            label="Enter payment information"
                            style={styles.submitButton}
                            textStyle={styles.submitButtonText}
                            type="submit"
                            disabled={!this.submitButtonEnabled()}
                            hoverStyle={styles.submitButtonHover}
                        />
                    </div>
                    {this.props.errors &&
                        this.props.errors.map((e, index) => {
                            return (
                                <div key={index} style={styles.errorContainer}>
                                    <div style={styles.errorContent}>
                                        <p style={styles.errorText}>
                                            <MdError
                                                size={20}
                                                color={DefaultColors.red[600]}
                                                style={{ marginRight: 8 }}
                                            />
                                            {e.message}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    <div style={styles.disclaimer}>
                        <div style={styles.disclaimerContent}>
                            <p style={styles.disclaimerText}>
                                <BsFillInfoCircleFill
                                    color={DefaultColors.gray[500]}
                                    style={{ marginRight: 8 }}
                                />
                                <strong>Don't worry</strong>: we ask for a
                                credit card on the next page but you won’t be
                                charged for 7 days.
                            </p>
                        </div>
                    </div>
                </div>
                <div style={styles.createAccountContainer}>
                    <span style={styles.createAccountText}>
                        Already have an account?{" "}
                        <Link style={styles.createAccountLink} to="/login">
                            Login
                        </Link>
                    </span>
                </div>
            </form>
        )
    }
}

export default SignUpForm
