import Input from "components/forms/input"
import * as React from "react"
import Plan from "./plan"
import styles from "./styles"

type Props = {
    email?: string
    password?: string
    passwordConfirm?: string
    name?: string
    onChangeField: (field: string, value: string) => void
    plans: any[]
    plan?: string
}

export default (props: Props) => {
    const requestChangeField = (value: string, field: string) => {
        props.onChangeField(field, value)
    }
    return (
        <div style={styles.fields}>
            <div style={styles.row}>
                <Input
                    label="Name"
                    value={props.name}
                    type="text"
                    onChange={(value) => requestChangeField(value, "name")}
                    style={styles.halfWidthField}
                />
            </div>
            <div style={styles.row}>
                <Input
                    label="Email"
                    value={props.email}
                    type="text"
                    onChange={(value) => requestChangeField(value, "email")}
                    style={styles.fullWidthField}
                />
            </div>
            <div style={styles.row}>
                <div style={styles.twoColumn}>
                    <Input
                        label="Password"
                        value={props.password}
                        type="password"
                        onChange={(value) =>
                            requestChangeField(value, "password")
                        }
                        style={styles.halfWidthField}
                    />
                </div>
                <div style={styles.twoColumn}>
                    <Input
                        label="Confirm"
                        value={props.passwordConfirm}
                        type="password"
                        onChange={(value) =>
                            requestChangeField(value, "passwordConfirm")
                        }
                        style={styles.halfWidthField}
                    />
                </div>
            </div>
            <div style={styles.row}>
                <div
                    style={Object.assign({}, styles.twoColumn, {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%"
                    })}
                >
                    {props.plans.map((plan) => {
                        return (
                            <Plan
                                onSelect={requestChangeField}
                                selected={props.plan === plan.id}
                                plan={plan}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
