import Colors from "../../colors"

export default {
    deleteButton: {
        backgroundColor: Colors.red[600],
        transition: "all .25s"
    },
    cancelButton: {
        backgroundColor: Colors.gray[300],
        transition: "all .25s"
    },
    cancelButtonText: {
        color: Colors.gray[700]
    },
    deleteButton_hover: {
        backgroundColor: Colors.red[700]
    },
    cancelButton_hover: {
        backgroundColor: Colors.gray[400]
    },
    card: {
        backgroundColor: Colors.white,
        boxShadow: "0px 0px 20px rgba(0,0,0,.12)",
        borderRadius: 8,
        display: "flex",
        flex: 1
        // overflowY: "scroll" as "scroll"
    },
    mainActionsBar: {
        // flex: 1,
        display: "flex",
        flexDirection: "row" as "row",
        alignItems: "center" as "center",
        justifyContent: "space-between" as "space-between",
        // position: "fixed" as "fixed",
        // top: 80,
        // top: 0,
        width: "100%",
        // height: 120,
        backgroundColor: "#f9f9f9",
        marginBottom: 36,
        marginTop: 12
        // marginLeft: -24
    },
    mainActionsBarInner: {
        // paddingLeft: 24,
        // paddingRight: 24,
        display: "flex",
        flexDirection: "row" as "row",
        alignItems: "flex-start" as "flex-start",
        justifyContent: "space-between" as "space-between",
        width: "100%"
    },
    filters: {},
    ctaContainer: {
        display: "flex",
        flexDirection: "row" as "row"
    }
}
