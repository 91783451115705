import { DefaultColors } from "util/colors"

export default {
    container: {
        display: "flex" as "flex",
        flexDirection: "column" as "column",
        marginBottom: 16,
        flex: 1
    },
    inline: {
        flexDirection: "row" as "row",
        alignItems: "center" as "center"
    },
    input: {
        height: 38,
        width: 260,
        borderRadius: 6,
        // border: "1px solid",
        // borderColor: DefaultColors.gray[400],
        border: 0,
        paddingLeft: 8,
        fontSize: 15,
        fontWeight: 400,
        color: DefaultColors.gray[800],
        // boxShadow: `0px 1px 2px rgba(0,0,0, .05)`,
        paddingRight: 8
    },
    inputContainer: {
        border: "1px solid",
        borderColor: DefaultColors.gray[400],
        borderRadius: 6,
        boxShadow: `0px 1px 2px rgba(0,0,0, .05)`,
        display: "flex"
        // alignItems: "center"
    },
    password: {
        fontSize: 14
    },
    label: {
        color: DefaultColors.gray[700],
        fontSize: 15,
        fontWeight: 500,
        width: 300
    },
    unit: {
        fontSize: 12
    },
    unitsContainer: {
        width: 50,
        display: "flex",
        justifyContent: "center" as "center",
        alignItems: "center" as "center",
        backgroundColor: DefaultColors.gray[100],
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        borderLeft: `1px solid ${DefaultColors.gray[400]}`
    },
    inputWithUnits: {
        borderRadiusTopRight: 0,
        borderRadiusBottomRight: 0
    }
}
