import { Colors } from "util/colors"

export default {
    screenContainer: {
        // backgroundColor: Colors.black,
        flex: 1,
        alignItems: "center" as "center",
        display: "flex",
        // flexDirection: "column" as "column",
        justifyContent: "center" as "center"
    },
    container: {
        width: 500,
        background: "#f9f9f9",
        margin: "0 auto",
        borderRadius: 3,
        display: "flex",
        marginTop: 24
    },
    formContainer: {
        flex: 1
    },
    main: {
        backgroundColor: "#fff",
        width: "58%",
        // height: "100%",
        minHeight: "100%"
    },
    mainInner: {
        width: "66%",
        margin: "0 auto",
        paddingTop: 32
    },
    aside: {
        backgroundColor: Colors.red,
        width: "42%",
        minHeight: "100%",
        height: "auto",
        flex: 1,
        display: "flex"
    },
    asideInner: {
        width: "66%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center" as "center",
        // flex: 1,
        justifyContent: "center" as "center"
    },
    heading: {
        color: "rgba(22,30,46, 1)",
        fontSize: 40
    },
    tagline: {
        fontSize: 18,
        fontWeight: 300 as 300,
        color: Colors.black,
        marginTop: 12,
        marginBottom: 40,
        lineHeight: 2
    },
    bolder: {
        fontWeight: 600 as 600
    },
    lineBreak: {
        background: Colors.ltGray,
        border: "none",
        height: 1
    },
    linksContainer: {
        paddingTop: 36
    },
    text: {
        fontWeight: 300,
        fontSize: 14,
        color: Colors.gray
    },
    link: {
        color: Colors.red,
        fontWeight: 400 as 400,
        cursor: "pointer"
    }
}
