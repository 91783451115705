import * as React from "react"
import { withAppContext, Context } from "../../../Context"
import styles from "./styles"
import Menu from "./menu"
import MenuItem from "./menu/item"
import { Location } from "@reach/router"
import { CheveronDown, UserSolidCircle, Cog, StandBy, TravelCar } from "react-zondicons"
import { Link } from "@reach/router"
const { useState } = React

type Props = {
    context: Context
}

const Header = (props: Props) => {
    const [showMenu, setShowMenu] = useState(null)
    const setActiveVehicle = (id: string) => {
        props.context.setActiveVehicle(id)
        setShowMenu(null)
    }
    return (
        <Location>
            {locationProps => (
                <>
                    <div style={styles.container}>
                        <div style={styles.contentContainer}>
                            <img src={require("../../../images/logo.png")} width="180" />
                            <Menu orientation="horiztonal">
                                <MenuItem
                                    to="/"
                                    text="Runs"
                                    icon={require("../../../images/stopwatch.svg")}
                                    active={
                                        locationProps.location.pathname === "/" ||
                                        locationProps.location.pathname.includes("runs")
                                    }
                                />
                                <MenuItem
                                    to="/weather"
                                    text="Weather"
                                    icon={require("../../../images/day-cloud.svg")}
                                    active={locationProps.location.pathname.includes(
                                        "weather"
                                    )}
                                />
                                <MenuItem
                                    to="/vehicles"
                                    text="Vehicles"
                                    icon={require("../../../images/car-11.svg")}
                                    active={locationProps.location.pathname.includes(
                                        "vehicles"
                                    )}
                                />
                            </Menu>
                            <div style={styles.vehicleSelect} onMouseOver={() => setShowMenu("vehicle")} onMouseLeave={() => setShowMenu(null)}>
                                <TravelCar style={styles.vehicleIcon} size={18} />
                                <span style={styles.displayName}>{props.context.state.selectedVehicle?.name}</span>
                                <CheveronDown style={styles.cheveronIcon} />
                                {showMenu === "vehicle" &&
                                    <div style={styles.menuContainer}>
                                        <ul style={styles.menu}>
                                            {props.context.state.vehicles.map((v, index) => {
                                                const last = index === (props.context.state.vehicles.length - 1) ? true : false
                                                return (
                                                    <li key={v.id} style={Object.assign({}, styles.menuItem, last && styles.menuItemLast)}>
                                                        <a style={styles.menuItemText} onClick={() => setActiveVehicle(v.id)}>
                                                            {v.name}
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                }
                            </div>
                            <div style={styles.userInfo} onMouseOver={() => setShowMenu("user")} onMouseLeave={() => setShowMenu(null)}>
                                <UserSolidCircle style={styles.userIcon} />
                                <span style={styles.displayName}>{props.context.state.user.displayName}</span>
                                <CheveronDown style={styles.cheveronIcon} />
                                {showMenu === "user" &&
                                    <div style={styles.menuContainer}>
                                        <ul style={styles.menu}>
                                            <li style={styles.menuItem}>
                                                <Link

                                                    to={"/settings"}
                                                >
                                                    <Cog size={14} style={styles.menuItemIcon} />
                                                    <span style={styles.menuItemText}>Settings</span>
                                                </Link>

                                            </li>
                                            <li style={Object.assign({}, styles.menuItem, styles.menuItemLast)}>
                                                <a onClick={props.context.logout}>
                                                    <StandBy size={14} style={styles.menuItemIcon} />
                                                    <span style={styles.menuItemText}>Logout</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 80 }}></div>
                </>
            )}
        </Location>
    )
}

export default withAppContext(Header)
