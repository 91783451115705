import { Redirect } from "@reach/router"
import * as React from "react"
import { Consumer, Context } from "../Context"

export const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Consumer>
        {(context: Context) => {
            return context.state.user ? (
                <Component {...rest} />
            ) : (
                <Redirect from="" to="login" noThrow />
            )
        }}
    </Consumer>
)

export const SubscribedRoute = ({ component: Component, ...rest }) => (
    <Consumer>
        {(context: Context) => {
            if (context.state.user && context.state.activeSubscription) {
                return <Component {...rest} />
            } else if (
                context.state.user &&
                context.state.checkingSubscriptions
            ) {
                return null
            } else {
                return <Redirect from="" to="settings" noThrow />
            }
        }}
    </Consumer>
)

export const PublicRoute = ({ component: Component, ...rest }) => (
    <Component {...rest} />
)
