import * as React from "react"
import ReactSelect from "react-select"
import styles from "./styles"

type Props = {
    label?: string
    value?: string
    onChange: (value: string) => void
    testId?: string
    inline?: boolean
    placeholder?: string
    style?: any
    options?: any
    onInputChange: (value: string) => void
    defaultValue: { label: any; value: any }
    name: string
    isClearable?: boolean
    disabled?: boolean
}

const Select: React.StatelessComponent<Props> = (props: Props) => (
    <div
        style={Object.assign(
            {},
            styles.container,
            props.inline && styles.inline,
            { width: 400 },
            props.style
        )}
    >
        <label
            style={Object.assign(
                {},
                styles.label,
                !props.inline && { marginBottom: 8 }
            )}
        >
            {props.label}
        </label>
        <ReactSelect
            name={props.name}
            isClearable={props.isClearable}
            onChange={props.onChange}
            onInputChange={props.onInputChange}
            options={props.options}
            defaultValue={props.defaultValue}
            isDisabled={props.disabled}
        />
    </div>
)

Select.defaultProps = {
    testId: "select"
}

export default Select
