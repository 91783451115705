import * as React from "react"
import styles from "./styles"
import AppContext, { Context } from "context"
import { fieldToLabel, getColorForField, labelToField } from "util/helpers"
import { RunFilterField } from "types/index"
import Pill from "components/pill"
import Button from "components/button"
const { useContext } = React
type Props = {
    onClearFilters: () => void
    onClearSingle: (filter: RunFilterField, value: string) => void
}
export default (props: Props) => {
    const { resetFilters, state } = useContext<Context>(AppContext)
    const { filters } = state
    const filterItems = Object.keys(filters)
        .map(key => {
            if (
                key !== "order" &&
                key !== "sortBy" && (
                    filters[key] && typeof filters[key] === "object" && filters[key].length !== 0
                )) {
                const label = fieldToLabel(key as RunFilterField)
                return {
                    label: label,
                    value: filters[key],
                    color: getColorForField(key as RunFilterField)
                }
            }
            return undefined
        })
        .filter(k => {
            return k && k.value !== null && k.value !== "" && k.value !== []
        })
        .filter(i => i)
    const handleClearFilters = () => {
        resetFilters()
        props.onClearFilters()
    }
    if (filterItems.length) {
        return (
            <div style={styles.container}>
                <span style={styles.title}>Active Filters:</span>
                <div style={styles.pillContainer}>
                    {filterItems.map(item => {
                        return item.value.map(i => {
                            return (
                                <Pill
                                    key={`${item.label}: ${i}`}
                                    text={`${item.label}: ${i}`}
                                    fill={item.color[500]}
                                    textColor={item.color[100]}
                                    onClick={() => { props.onClearSingle(labelToField(item.label), i) }}
                                />
                            )
                        })
                    })}
                </div>
                <Button
                    type="button"
                    style={styles.clearFiltersButton}
                    textStyle={styles.clearFiltersButtonText}
                    hoverStyle={styles.clearFiltersButtonHover}
                    hoverTextStyle={styles.clearFiltersButtonHoverText}
                    onClick={handleClearFilters}
                    label="Clear Filters"
                />
            </div>
        )
    }
    return null
}