import * as React from "react"
import styles from "./styles"

type Props = {
    children: any
    orientation: string
}

const Menu: React.StatelessComponent<Props> = (props: Props) => (
    <div style={styles.container}>
        <ul style={styles.menu}>{props.children}</ul>
    </div>
)

Menu.defaultProps = {
    orientation: "vertical"
}

export default Menu
