import { Router } from "@reach/router"
import * as React from "react"
import "react-dates/initialize"
import { render } from "react-dom"
import { StripeProvider } from "react-stripe-elements"
import "regenerator-runtime/runtime"
import { ProtectedRoute, PublicRoute, SubscribedRoute } from "./routes"
import ConfirmEmail from "./routes/confirm-email"
import ETPrediction from "./routes/et-prediction"
import ForgotPassword from "./routes/forgot-password"
import Home from "./routes/home"
import Login from "./routes/login"
import Run from "./routes/run"
import Settings from "./routes/settings"
import Signup from "./routes/signup/index"
import Vehicles from "./routes/vehicles"
import Weather from "./routes/weather"
import "./styles.css"
import Provider from "./Provider"

class App extends React.Component<{}, {}> {
    componentDidMount() {
        this.addOnlineListeners()
    }
    addOnlineListeners = () => {
        window.addEventListener("offline", function (e) {
            console.log("offline")
        })

        window.addEventListener("online", function (e) {
            console.log("online")
        })
    }
    removeOnlineListeners = () => {
        window.removeEventListener("online", null)
        window.removeEventListener("offline", null)
    }
    render() {
        return (
            <StripeProvider apiKey="pk_test_00Oe8BeSDD6VstiPZvdJ0d2B">
                <Provider>
                    <Router style={{ minHeight: "100%", display: "flex" }}>
                        <PublicRoute path="/login" component={Login} />
                        <PublicRoute
                            path="/forgot-password"
                            component={ForgotPassword}
                        />
                        <PublicRoute path="/sign-up" component={Signup} />
                        <PublicRoute
                            path="/confirm-email/:email"
                            component={ConfirmEmail}
                        />
                        <SubscribedRoute path="/" component={Home} />
                        <SubscribedRoute path="/runs/:id" component={Run} />
                        <SubscribedRoute path="/weather" component={Weather} />
                        <SubscribedRoute
                            path="/vehicles"
                            component={Vehicles}
                        />
                        <SubscribedRoute
                            path="/et-prediction"
                            component={ETPrediction}
                        />
                        <ProtectedRoute path="/settings" component={Settings} />
                    </Router>
                </Provider>
            </StripeProvider>
        )
    }
}

render(<App />, document.getElementById("root"))
