import Button from "components/button"
import * as React from "react"
import Popup from "../../components/popup"
import VehiclesList from "../../components/vehicles"
import { DashboardLayout as Layout } from "../../layouts"
import { withAppContext, Context } from "../../Context"
import styles from "./styles"

type Props = {
    context: Context
}
type State = {
    vehicleToDelete?: string
    showPopup?: boolean
}

class Vehicles extends React.Component<Props, State> {
    state = {
        showPopup: false,
        vehicleToDelete: null
    }
    showPopup = (id: string) => {
        this.setState({ showPopup: true, vehicleToDelete: id })
    }
    hidePopup = () => {
        this.setState({ showPopup: false, vehicleToDelete: null })
    }
    confirmDeleteRun = () => {
        this.props.context.deleteRun(this.state.vehicleToDelete)
        this.hidePopup()
    }
    render() {
        return (
            <Layout>
                <div style={{ width: "100%", marginTop: 84 }}>
                    {this.props.context.state.vehicles && (
                        <VehiclesList
                            data={this.props.context.state.vehicles}
                            context={this.props.context}
                            onRequestDelete={this.showPopup}
                        />
                    )}
                    {this.state.showPopup && (
                        <Popup
                            requestHide={this.hidePopup}
                            actions={
                                <React.Fragment>
                                    <Button
                                        label="Cancel"
                                        style={styles.cancelButton}
                                        hoverStyle={styles.cancelButton_hover}
                                        textStyle={styles.cancelButtonText}
                                        onClick={this.hidePopup}
                                    />
                                    <Button
                                        label="Delete"
                                        style={Object.assign(
                                            {},
                                            styles.deleteButton,
                                            {
                                                marginLeft: 8
                                            }
                                        )}
                                        hoverStyle={styles.deleteButton_hover}
                                        onClick={this.confirmDeleteRun}
                                    />
                                </React.Fragment>
                            }
                            title="Are you sure you want to delete this run?"
                            subtitle="This action cannot be undone."
                        />
                    )}
                </div>
            </Layout >
        )
    }
}

export default withAppContext(Vehicles)
export { Vehicles }
