import * as React from "react"
import { CreateUserAccountType } from "../../types"
import { withAppContext, Context } from "../../Context"
import Reviews from "../signup/components/quotes"
import styles from "../signup/styles"

type Props = {
    context: Context
    email?: string
}

type State = {
    account?: CreateUserAccountType
    errors?: { code: string; message: string }[]
    period?: string
    plans?: any[]
}

export class SignUp extends React.Component<Props, State> {
    state = {
        period: null,
        plans: [],
        account: null,
        errors: []
    }
    componentDidMount() {
        if (this.props.context.state.user) {
            console.log("user exists and is logged in: ")
        }
    }

    handleSubmit = (account: CreateUserAccountType) => {
        this.setState({ account, errors: [] }, () => {
            this.props.context.firebaseApp
                .auth()
                .createUserWithEmailAndPassword(account.email, account.password)
                .then((newUser) => {
                    const { user } = newUser
                    this.addUserToDatabase(user)
                    user.sendEmailVerification()
                    user.updateProfile({
                        displayName: account.name,
                        photoURL: null
                    })
                    this.handleSuccessAccountCreation()
                })
                .catch((error) => {
                    if (error) {
                        const { code, message } = error
                        this.setState({ errors: [{ code, message }] })
                    }
                })
        })
    }
    addUserToDatabase = (user: any) => {
        this.props.context.firebaseDB.post(`users/${user.uid}`, {
            data: {
                id: user.uid,
                name: this.state.account.name,
                subscribed: false,
                email: user.email,
                emailVerified: user.emailVerified,
                creationTime: user.metadata.creationTime,
                lastSignInTime: user.metadata.lastSignInTime
            }
        })
    }
    handleSuccessAccountCreation = () => {}
    requestResendVerificationEmail = async () => {
        if (this.props.context?.state?.user) {
            this.props.context.firebaseApp
                .auth()
                .currentUser.sendEmailVerification({ url: "localhost:3000" })
        } else {
            if (this.props.email) {
                const response = await fetch(
                    `http://localhost:5001/dragtuner-de058/us-central1/sendVerificationEmail`,
                    {
                        method: "POST",
                        body: JSON.stringify({ email: this.props.email })
                    }
                ).then((r) => r.json())
                console.log(response)
            }
        }
    }
    render() {
        return (
            <div style={styles.screenContainer}>
                <div style={styles.main}>
                    <div style={styles.mainInner}>
                        <a href="https://dragtuner.com">
                            <img
                                src={require("../../images/dt-logo-horizontal.png")}
                                style={{ width: 225, marginBottom: 80 }}
                            />
                        </a>
                        <h1 style={styles.heading}>Almost done!</h1>
                        <p style={styles.tagline}>
                            Please click the email confirmation we sent to{" "}
                            <span style={styles.bolder}>
                                {this.props.email}
                            </span>
                            . We require this so folks can't impersonate
                            eachother on DragTuner.
                        </p>
                        <div style={styles.lineBreak}></div>
                        <div style={styles.linksContainer}>
                            <p style={styles.text}>
                                Not seeing the email?{" "}
                                <a
                                    style={styles.link}
                                    onClick={
                                        this.requestResendVerificationEmail
                                    }
                                >
                                    Request a new one
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div style={styles.aside}>
                    <div style={styles.asideInner}>
                        <Reviews />
                    </div>
                </div>
            </div>
        )
    }
}

export default withAppContext(SignUp)
